<template>
  <!-- eslint-disable -->
  <div id='buildings'>
    <svg xmlns="http://www.w3.org/2000/svg" id="buildings" viewBox="0 0 320 260" text-rendering="geometricPrecision" shape-rendering="geometricPrecision" style="white-space: pre;">
    <defs>
        <linearGradient id="Gradient-0" x1="1.3285" y1="16.9487" x2="98.4827" y2="108.836" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#200047"/>
            <stop offset="1" stop-color="#420e68"/>
        </linearGradient>
        <linearGradient id="Gradient-1" x1="3.8606" y1="9.9807" x2="29.4045" y2="177.804" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#30006b"/>
            <stop offset="1" stop-color="#58138c"/>
        </linearGradient>
        <linearGradient id="Gradient-2" x1="32.7586" y1="62.1077" x2="43.8348" y2="106.35" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#2f006a"/>
            <stop offset="1" stop-color="#57138b"/>
        </linearGradient>
        <linearGradient id="Gradient-3" x1="6.2858" y1="60.9847" x2="16.9229" y2="111.233" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#4c00ab"/>
            <stop offset="1" stop-color="#801ccc"/>
        </linearGradient>
        <linearGradient id="Gradient-4" x1="35.166" y1="22.3479" x2="46.2424" y2="66.5909" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#2f006a"/>
            <stop offset="1" stop-color="#57138b"/>
        </linearGradient>
        <linearGradient id="Gradient-5" x1="8.6061" y1="21.3359" x2="19.2436" y2="71.5863" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#4c00ab"/>
            <stop offset="1" stop-color="#801ccc"/>
        </linearGradient>
        <linearGradient id="Gradient-6" x1="0.2883" y1="17.477" x2="51.5495" y2="17.477" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#4c00ab"/>
            <stop offset="1" stop-color="#801ccc"/>
        </linearGradient>
        <linearGradient id="Gradient-7" x1="2.3947" y1="17.4987" x2="49.4505" y2="17.4987" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#9863db"/>
            <stop offset="1" stop-color="#cf94fc"/>
        </linearGradient>
        <linearGradient id="Gradient-8" x1="37.1782" y1="15.4577" x2="45.5283" y2="11.4036" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#333333"/>
            <stop offset="1" stop-color="#cccccc"/>
        </linearGradient>
        <linearGradient id="Gradient-9" x1="36.6195" y1="13.3547" x2="41.3651" y2="11.0507" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#333333"/>
            <stop offset="1" stop-color="#cccccc"/>
        </linearGradient>
        <linearGradient id="Gradient-10" x1="27.1353" y1="14.1628" x2="30.8953" y2="13.8125" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#333333"/>
            <stop offset="1" stop-color="#cccccc"/>
        </linearGradient>
        <linearGradient id="Gradient-11" x1="20.3148" y1="17.6899" x2="24.0753" y2="17.3395" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#333333"/>
            <stop offset="1" stop-color="#cccccc"/>
        </linearGradient>
        <linearGradient id="Gradient-12" x1="13.2593" y1="21.4534" x2="17.0198" y2="21.103" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#333333"/>
            <stop offset="1" stop-color="#cccccc"/>
        </linearGradient>
        <linearGradient id="Gradient-13" x1="32.7586" y1="56.5486" x2="43.8348" y2="100.791" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#2f006a"/>
            <stop offset="1" stop-color="#57138b"/>
        </linearGradient>
        <linearGradient id="Gradient-14" x1="6.2858" y1="55.4257" x2="16.9229" y2="105.674" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#4c00ab"/>
            <stop offset="1" stop-color="#801ccc"/>
        </linearGradient>
        <linearGradient id="Gradient-15" x1="32.7586" y1="50.9896" x2="43.8348" y2="95.2318" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#2f006a"/>
            <stop offset="1" stop-color="#57138b"/>
        </linearGradient>
        <linearGradient id="Gradient-16" x1="6.2858" y1="49.8667" x2="16.9229" y2="100.115" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#4c00ab"/>
            <stop offset="1" stop-color="#801ccc"/>
        </linearGradient>
        <linearGradient id="Gradient-17" x1="32.7586" y1="45.4306" x2="43.8348" y2="89.6728" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#2f006a"/>
            <stop offset="1" stop-color="#57138b"/>
        </linearGradient>
        <linearGradient id="Gradient-18" x1="6.2858" y1="44.3076" x2="16.9229" y2="94.556" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#4c00ab"/>
            <stop offset="1" stop-color="#801ccc"/>
        </linearGradient>
        <linearGradient id="Gradient-19" x1="32.7586" y1="39.8715" x2="43.8348" y2="84.1137" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#2f006a"/>
            <stop offset="1" stop-color="#57138b"/>
        </linearGradient>
        <linearGradient id="Gradient-20" x1="6.2858" y1="38.7486" x2="16.9229" y2="88.9969" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#4c00ab"/>
            <stop offset="1" stop-color="#801ccc"/>
        </linearGradient>
        <linearGradient id="Gradient-21" x1="32.7586" y1="34.3125" x2="43.8348" y2="78.5547" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#2f006a"/>
            <stop offset="1" stop-color="#57138b"/>
        </linearGradient>
        <linearGradient id="Gradient-22" x1="6.2858" y1="33.1896" x2="16.9229" y2="83.4379" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#4c00ab"/>
            <stop offset="1" stop-color="#801ccc"/>
        </linearGradient>
        <linearGradient id="Gradient-23" x1="32.7586" y1="28.7535" x2="43.8348" y2="72.9957" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#2f006a"/>
            <stop offset="1" stop-color="#57138b"/>
        </linearGradient>
        <linearGradient id="Gradient-24" x1="6.2858" y1="27.6305" x2="16.9229" y2="77.8789" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#4c00ab"/>
            <stop offset="1" stop-color="#801ccc"/>
        </linearGradient>
        <linearGradient id="Gradient-25" x1="32.7586" y1="23.1944" x2="43.8348" y2="67.4366" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#2f006a"/>
            <stop offset="1" stop-color="#57138b"/>
        </linearGradient>
        <linearGradient id="Gradient-26" x1="6.2858" y1="22.0715" x2="16.9229" y2="72.3198" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#4c00ab"/>
            <stop offset="1" stop-color="#801ccc"/>
        </linearGradient>
        <linearGradient id="Gradient-27" x1="32.7586" y1="17.6354" x2="43.8348" y2="61.8776" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#2f006a"/>
            <stop offset="1" stop-color="#57138b"/>
        </linearGradient>
        <linearGradient id="Gradient-28" x1="6.2858" y1="16.5125" x2="16.9229" y2="66.7608" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#4c00ab"/>
            <stop offset="1" stop-color="#801ccc"/>
        </linearGradient>
        <linearGradient id="Gradient-29" x1="27.4" y1="0" x2="27.4" y2="89.85" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#9863db"/>
            <stop offset="1" stop-color="#cf94fc"/>
        </linearGradient>
        <linearGradient id="Gradient-30" x1="116.194" y1="23.5127" x2="128.028" y2="75.3565" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#9543ff"/>
            <stop offset="1" stop-color="#ffffff"/>
        </linearGradient>
        <linearGradient id="Gradient-31" x1="106.476" y1="29.3268" x2="97.9898" y2="77.033" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#9543ff"/>
            <stop offset="1" stop-color="#ffffff"/>
        </linearGradient>
        <linearGradient id="Gradient-32" x1="79.5918" y1="26.5553" x2="129.354" y2="25.695" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#9863db"/>
            <stop offset="1" stop-color="#cf94fc"/>
        </linearGradient>
        <linearGradient id="Gradient-33" x1="186.837" y1="4.0298" x2="194.823" y2="76.3668" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#812fff"/>
            <stop offset="1" stop-color="#4ad6fc"/>
        </linearGradient>
        <linearGradient id="Gradient-34" x1="202.327" y1="4.4301" x2="212.049" y2="70.5181" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#3d1678"/>
            <stop offset="1" stop-color="#226475"/>
        </linearGradient>
        <linearGradient id="Gradient-35" x1="200.221" y1="15.4266" x2="217.351" y2="74.9174" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#812fff"/>
            <stop offset="1" stop-color="#4ad6fc"/>
        </linearGradient>
        <linearGradient id="Gradient-36" x1="191.46" y1="16.1933" x2="213.791" y2="82.8416" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#3d1678"/>
            <stop offset="1" stop-color="#226475"/>
        </linearGradient>
        <linearGradient id="Gradient-37" x1="192.656" y1="15.7684" x2="214.997" y2="82.4456" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#3d1678"/>
            <stop offset="1" stop-color="#226475"/>
        </linearGradient>
        <linearGradient id="Gradient-38" x1="193.86" y1="15.3644" x2="216.201" y2="82.0424" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#3d1678"/>
            <stop offset="1" stop-color="#226475"/>
        </linearGradient>
        <linearGradient id="Gradient-39" x1="195.063" y1="14.9611" x2="217.406" y2="81.642" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#3d1678"/>
            <stop offset="1" stop-color="#226475"/>
        </linearGradient>
        <linearGradient id="Gradient-40" x1="196.268" y1="14.559" x2="218.609" y2="81.2379" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#3d1678"/>
            <stop offset="1" stop-color="#226475"/>
        </linearGradient>
        <linearGradient id="Gradient-41" x1="197.471" y1="14.1544" x2="219.814" y2="80.8361" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#3d1678"/>
            <stop offset="1" stop-color="#226475"/>
        </linearGradient>
        <linearGradient id="Gradient-42" x1="198.68" y1="13.7668" x2="221.012" y2="80.4159" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#3d1678"/>
            <stop offset="1" stop-color="#226475"/>
        </linearGradient>
        <linearGradient id="Gradient-43" x1="199.879" y1="13.3501" x2="222.222" y2="80.031" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#3d1678"/>
            <stop offset="1" stop-color="#226475"/>
        </linearGradient>
        <linearGradient id="Gradient-44" x1="201.083" y1="12.9481" x2="223.425" y2="79.6261" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#3d1678"/>
            <stop offset="1" stop-color="#226475"/>
        </linearGradient>
        <linearGradient id="Gradient-45" x1="202.29" y1="12.5533" x2="224.622" y2="79.2045" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#3d1678"/>
            <stop offset="1" stop-color="#226475"/>
        </linearGradient>
        <linearGradient id="Gradient-46" x1="193.144" y1="15.6131" x2="215.482" y2="82.2798" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#3d1678"/>
            <stop offset="1" stop-color="#226475"/>
        </linearGradient>
        <linearGradient id="Gradient-47" x1="194.594" y1="15.1282" x2="216.931" y2="81.7932" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#3d1678"/>
            <stop offset="1" stop-color="#226475"/>
        </linearGradient>
        <linearGradient id="Gradient-48" x1="196.043" y1="14.6415" x2="218.38" y2="81.307" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#3d1678"/>
            <stop offset="1" stop-color="#226475"/>
        </linearGradient>
        <linearGradient id="Gradient-49" x1="197.491" y1="14.1564" x2="219.829" y2="80.8232" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#3d1678"/>
            <stop offset="1" stop-color="#226475"/>
        </linearGradient>
        <linearGradient id="Gradient-50" x1="198.941" y1="13.6716" x2="221.278" y2="80.3363" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#3d1678"/>
            <stop offset="1" stop-color="#226475"/>
        </linearGradient>
        <linearGradient id="Gradient-51" x1="200.39" y1="13.185" x2="222.727" y2="79.8516" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#3d1678"/>
            <stop offset="1" stop-color="#226475"/>
        </linearGradient>
        <linearGradient id="Gradient-52" x1="201.84" y1="12.6997" x2="224.177" y2="79.3653" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#3d1678"/>
            <stop offset="1" stop-color="#226475"/>
        </linearGradient>
        <linearGradient id="Gradient-53" x1="203.289" y1="12.2149" x2="225.626" y2="78.8798" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#3d1678"/>
            <stop offset="1" stop-color="#226475"/>
        </linearGradient>
        <linearGradient id="Gradient-54" x1="185.432" y1="7.2274" x2="195.27" y2="70.884" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#1b1c54"/>
            <stop offset="1" stop-color="#542488"/>
        </linearGradient>
        <linearGradient id="Gradient-55" x1="180.824" y1="6.2243" x2="193.324" y2="79.3694" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#812fff"/>
            <stop offset="1" stop-color="#4ad6fc"/>
        </linearGradient>
        <linearGradient id="Gradient-56" x1="181.488" y1="6.1114" x2="193.987" y2="79.2559" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#812fff"/>
            <stop offset="1" stop-color="#4ad6fc"/>
        </linearGradient>
        <linearGradient id="Gradient-57" x1="182.151" y1="5.9976" x2="194.651" y2="79.1427" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#812fff"/>
            <stop offset="1" stop-color="#4ad6fc"/>
        </linearGradient>
        <linearGradient id="Gradient-58" x1="182.815" y1="5.8842" x2="195.314" y2="79.0294" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#812fff"/>
            <stop offset="1" stop-color="#4ad6fc"/>
        </linearGradient>
        <linearGradient id="Gradient-59" x1="183.478" y1="5.7709" x2="195.978" y2="78.9159" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#812fff"/>
            <stop offset="1" stop-color="#4ad6fc"/>
        </linearGradient>
        <linearGradient id="Gradient-60" x1="184.142" y1="5.6576" x2="196.641" y2="78.8027" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#812fff"/>
            <stop offset="1" stop-color="#4ad6fc"/>
        </linearGradient>
        <linearGradient id="Gradient-61" x1="184.805" y1="5.5441" x2="197.305" y2="78.6892" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#812fff"/>
            <stop offset="1" stop-color="#4ad6fc"/>
        </linearGradient>
        <linearGradient id="Gradient-62" x1="185.469" y1="5.4336" x2="197.968" y2="78.5712" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#812fff"/>
            <stop offset="1" stop-color="#4ad6fc"/>
        </linearGradient>
        <linearGradient id="Gradient-63" x1="186.132" y1="5.3162" x2="198.632" y2="78.4613" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#812fff"/>
            <stop offset="1" stop-color="#4ad6fc"/>
        </linearGradient>
        <linearGradient id="Gradient-64" x1="186.795" y1="5.2029" x2="199.295" y2="78.3481" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#812fff"/>
            <stop offset="1" stop-color="#4ad6fc"/>
        </linearGradient>
        <linearGradient id="Gradient-65" x1="203.443" y1="12.1671" x2="225.775" y2="78.8154" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#3d1678"/>
            <stop offset="1" stop-color="#226475"/>
        </linearGradient>
        <linearGradient id="Gradient-66" x1="187.431" y1="5.0944" x2="199.931" y2="78.2396" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#812fff"/>
            <stop offset="1" stop-color="#4ad6fc"/>
        </linearGradient>
        <linearGradient id="Gradient-67" x1="204.688" y1="11.7419" x2="227.03" y2="78.4228" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#3d1678"/>
            <stop offset="1" stop-color="#226475"/>
        </linearGradient>
        <linearGradient id="Gradient-68" x1="188.119" y1="4.9764" x2="200.618" y2="78.1217" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#812fff"/>
            <stop offset="1" stop-color="#4ad6fc"/>
        </linearGradient>
        <linearGradient id="Gradient-69" x1="205.994" y1="11.309" x2="228.327" y2="77.961" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#3d1678"/>
            <stop offset="1" stop-color="#226475"/>
        </linearGradient>
        <linearGradient id="Gradient-70" x1="188.838" y1="4.8543" x2="201.337" y2="77.9994" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#812fff"/>
            <stop offset="1" stop-color="#4ad6fc"/>
        </linearGradient>
        <linearGradient id="Gradient-71" x1="191.148" y1="4.4591" x2="203.648" y2="77.6051" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#812fff"/>
            <stop offset="1" stop-color="#4ad6fc"/>
        </linearGradient>
        <linearGradient id="Gradient-72" x1="189.963" y1="4.6616" x2="202.463" y2="77.8083" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#812fff"/>
            <stop offset="1" stop-color="#4ad6fc"/>
        </linearGradient>
        <linearGradient id="Gradient-73" x1="188.778" y1="4.863" x2="201.278" y2="78.011" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#812fff"/>
            <stop offset="1" stop-color="#4ad6fc"/>
        </linearGradient>
        <linearGradient id="Gradient-74" x1="187.593" y1="5.0656" x2="200.093" y2="78.2139" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#812fff"/>
            <stop offset="1" stop-color="#4ad6fc"/>
        </linearGradient>
        <linearGradient id="Gradient-75" x1="186.407" y1="5.2692" x2="198.907" y2="78.415" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#812fff"/>
            <stop offset="1" stop-color="#4ad6fc"/>
        </linearGradient>
        <linearGradient id="Gradient-76" x1="185.223" y1="5.4719" x2="197.723" y2="78.618" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#812fff"/>
            <stop offset="1" stop-color="#4ad6fc"/>
        </linearGradient>
        <linearGradient id="Gradient-77" x1="184.037" y1="5.6731" x2="196.537" y2="78.8211" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#812fff"/>
            <stop offset="1" stop-color="#4ad6fc"/>
        </linearGradient>
        <linearGradient id="Gradient-78" x1="182.852" y1="5.8757" x2="195.352" y2="79.0241" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#812fff"/>
            <stop offset="1" stop-color="#4ad6fc"/>
        </linearGradient>
        <linearGradient id="Gradient-79" x1="181.666" y1="6.0786" x2="194.166" y2="79.227" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#812fff"/>
            <stop offset="1" stop-color="#4ad6fc"/>
        </linearGradient>
        <linearGradient id="Gradient-80" x1="180.481" y1="6.2817" x2="192.981" y2="79.4297" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#812fff"/>
            <stop offset="1" stop-color="#4ad6fc"/>
        </linearGradient>
        <linearGradient id="Gradient-81" x1="179.296" y1="6.4847" x2="191.796" y2="79.6298" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#812fff"/>
            <stop offset="1" stop-color="#4ad6fc"/>
        </linearGradient>
        <linearGradient id="Gradient-82" x1="178.111" y1="6.6876" x2="190.61" y2="79.8336" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#812fff"/>
            <stop offset="1" stop-color="#4ad6fc"/>
        </linearGradient>
        <linearGradient id="Gradient-83" x1="205.484" y1="10.5189" x2="208.842" y2="10.206" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#333333"/>
            <stop offset="1" stop-color="#cccccc"/>
        </linearGradient>
        <linearGradient id="Gradient-84" x1="188.081" y1="3.5128" x2="211.389" y2="13.3656" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#812fff"/>
            <stop offset="1" stop-color="#4ad6fc"/>
        </linearGradient>
        <linearGradient id="Gradient-85" x1="201.669" y1="11.0789" x2="206.394" y2="11.0789" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#812fff"/>
            <stop offset="1" stop-color="#4ad6fc"/>
        </linearGradient>
        <linearGradient id="Gradient-86" x1="200.358" y1="13.487" x2="203.715" y2="13.1741" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#333333"/>
            <stop offset="1" stop-color="#cccccc"/>
        </linearGradient>
        <linearGradient id="Gradient-87" x1="186.24" y1="7.8699" x2="209.545" y2="17.7218" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#812fff"/>
            <stop offset="1" stop-color="#4ad6fc"/>
        </linearGradient>
        <linearGradient id="Gradient-88" x1="196.543" y1="14.0469" x2="201.269" y2="14.0469" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#812fff"/>
            <stop offset="1" stop-color="#4ad6fc"/>
        </linearGradient>
        <linearGradient id="Gradient-89" x1="199.009" y1="6.4098" x2="202.367" y2="6.097" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#333333"/>
            <stop offset="1" stop-color="#cccccc"/>
        </linearGradient>
        <linearGradient id="Gradient-90" x1="188.573" y1="2.3497" x2="211.88" y2="12.2019" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#812fff"/>
            <stop offset="1" stop-color="#4ad6fc"/>
        </linearGradient>
        <linearGradient id="Gradient-91" x1="195.194" y1="6.9705" x2="199.921" y2="6.9705" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#812fff"/>
            <stop offset="1" stop-color="#4ad6fc"/>
        </linearGradient>
        <linearGradient id="Gradient-92" x1="193.633" y1="9.564" x2="196.991" y2="9.2512" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#333333"/>
            <stop offset="1" stop-color="#cccccc"/>
        </linearGradient>
        <linearGradient id="Gradient-93" x1="186.629" y1="6.9542" x2="209.926" y2="16.8025" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#812fff"/>
            <stop offset="1" stop-color="#4ad6fc"/>
        </linearGradient>
        <linearGradient id="Gradient-94" x1="189.819" y1="10.1239" x2="194.544" y2="10.1239" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#812fff"/>
            <stop offset="1" stop-color="#4ad6fc"/>
        </linearGradient>
        <linearGradient id="Gradient-95" x1="201.214" y1="75.6752" x2="215.813" y2="75.6752" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#3d1678"/>
            <stop offset="1" stop-color="#226475"/>
        </linearGradient>
        <linearGradient id="Gradient-96" x1="181.458" y1="74.0876" x2="201.216" y2="74.0876" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#812fff"/>
            <stop offset="1" stop-color="#4ad6fc"/>
        </linearGradient>
        <linearGradient id="Gradient-97" x1="-1.1248" y1="101.465" x2="27.2787" y2="121.194" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#9863db"/>
            <stop offset="1" stop-color="#cf94fc"/>
        </linearGradient>
        <linearGradient id="Gradient-98" x1="15.5262" y1="112.219" x2="40.4496" y2="182.02" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#9543ff"/>
            <stop offset="1" stop-color="#ffffff"/>
        </linearGradient>
        <linearGradient id="Gradient-99" x1="19.8888" y1="121.336" x2="-2.741" y2="180.051" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#9543ff"/>
            <stop offset="1" stop-color="#ffffff"/>
        </linearGradient>
        <linearGradient id="Gradient-100" x1="88.4779" y1="140.761" x2="138.053" y2="140.761" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#9863db"/>
            <stop offset="1" stop-color="#cf94fc"/>
        </linearGradient>
        <linearGradient id="Gradient-101" x1="91.9054" y1="140.632" x2="134.847" y2="140.632" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#4c00ab"/>
            <stop offset="1" stop-color="#801ccc"/>
        </linearGradient>
        <linearGradient id="Gradient-102" x1="135.572" y1="150.732" x2="135.572" y2="189.35" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#6c6c6c" stop-opacity="0.4"/>
            <stop offset="1" stop-color="#333333" stop-opacity="0.4"/>
        </linearGradient>
        <linearGradient id="Gradient-103" x1="131.644" y1="150.725" x2="131.644" y2="189.245" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#6c6c6c" stop-opacity="0.4"/>
            <stop offset="1" stop-color="#333333" stop-opacity="0.4"/>
        </linearGradient>
        <linearGradient id="Gradient-104" x1="127.726" y1="150.747" x2="127.726" y2="189.333" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#6c6c6c" stop-opacity="0.4"/>
            <stop offset="1" stop-color="#333333" stop-opacity="0.4"/>
        </linearGradient>
        <linearGradient id="Gradient-105" x1="123.794" y1="150.756" x2="123.794" y2="189.462" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#6c6c6c" stop-opacity="0.4"/>
            <stop offset="1" stop-color="#333333" stop-opacity="0.4"/>
        </linearGradient>
        <linearGradient id="Gradient-106" x1="119.876" y1="150.75" x2="119.876" y2="189.502" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#6c6c6c" stop-opacity="0.4"/>
            <stop offset="1" stop-color="#333333" stop-opacity="0.4"/>
        </linearGradient>
        <linearGradient id="Gradient-107" x1="115.953" y1="150.773" x2="115.953" y2="189.345" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#6c6c6c" stop-opacity="0.4"/>
            <stop offset="1" stop-color="#333333" stop-opacity="0.4"/>
        </linearGradient>
        <linearGradient id="Gradient-108" x1="135.572" y1="150.744" x2="135.572" y2="189.359" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#6c6c6c" stop-opacity="0.4"/>
            <stop offset="1" stop-color="#333333" stop-opacity="0.4"/>
        </linearGradient>
        <linearGradient id="Gradient-109" x1="131.644" y1="150.748" x2="131.644" y2="189.511" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#6c6c6c" stop-opacity="0.4"/>
            <stop offset="1" stop-color="#333333" stop-opacity="0.4"/>
        </linearGradient>
        <linearGradient id="Gradient-110" x1="127.726" y1="150.74" x2="127.726" y2="189.412" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#6c6c6c" stop-opacity="0.4"/>
            <stop offset="1" stop-color="#333333" stop-opacity="0.4"/>
        </linearGradient>
        <linearGradient id="Gradient-111" x1="123.794" y1="150.757" x2="123.794" y2="189.387" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#6c6c6c" stop-opacity="0.4"/>
            <stop offset="1" stop-color="#333333" stop-opacity="0.4"/>
        </linearGradient>
        <linearGradient id="Gradient-112" x1="119.876" y1="150.719" x2="119.876" y2="189.56" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#6c6c6c" stop-opacity="0.4"/>
            <stop offset="1" stop-color="#333333" stop-opacity="0.4"/>
        </linearGradient>
        <linearGradient id="Gradient-113" x1="115.953" y1="150.763" x2="115.953" y2="189.453" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#6c6c6c" stop-opacity="0.4"/>
            <stop offset="1" stop-color="#333333" stop-opacity="0.4"/>
        </linearGradient>
        <linearGradient id="Gradient-114" x1="135.572" y1="150.736" x2="135.572" y2="189.739" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#6c6c6c" stop-opacity="0.4"/>
            <stop offset="1" stop-color="#333333" stop-opacity="0.4"/>
        </linearGradient>
        <linearGradient id="Gradient-115" x1="131.644" y1="150.747" x2="131.644" y2="189.391" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#6c6c6c" stop-opacity="0.4"/>
            <stop offset="1" stop-color="#333333" stop-opacity="0.4"/>
        </linearGradient>
        <linearGradient id="Gradient-116" x1="127.726" y1="150.759" x2="127.726" y2="189.421" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#6c6c6c" stop-opacity="0.4"/>
            <stop offset="1" stop-color="#333333" stop-opacity="0.4"/>
        </linearGradient>
        <linearGradient id="Gradient-117" x1="123.794" y1="150.714" x2="123.794" y2="189.611" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#6c6c6c" stop-opacity="0.4"/>
            <stop offset="1" stop-color="#333333" stop-opacity="0.4"/>
        </linearGradient>
        <linearGradient id="Gradient-118" x1="119.876" y1="150.792" x2="119.876" y2="189.339" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#6c6c6c" stop-opacity="0.4"/>
            <stop offset="1" stop-color="#333333" stop-opacity="0.4"/>
        </linearGradient>
        <linearGradient id="Gradient-119" x1="115.953" y1="150.725" x2="115.953" y2="189.505" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#6c6c6c" stop-opacity="0.4"/>
            <stop offset="1" stop-color="#333333" stop-opacity="0.4"/>
        </linearGradient>
        <linearGradient id="Gradient-120" x1="90.9614" y1="148.826" x2="90.9614" y2="187.678" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#9a9a9a" stop-opacity="0.37"/>
            <stop offset="0.9835" stop-color="#757575" stop-opacity="0.19"/>
        </linearGradient>
        <linearGradient id="Gradient-121" x1="94.8988" y1="148.812" x2="94.8988" y2="187.366" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#9a9a9a" stop-opacity="0.37"/>
            <stop offset="0.9835" stop-color="#757575" stop-opacity="0.19"/>
        </linearGradient>
        <linearGradient id="Gradient-122" x1="98.8125" y1="148.813" x2="98.8125" y2="187.476" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#9a9a9a" stop-opacity="0.37"/>
            <stop offset="0.9835" stop-color="#757575" stop-opacity="0.19"/>
        </linearGradient>
        <linearGradient id="Gradient-123" x1="102.745" y1="148.786" x2="102.745" y2="187.636" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#9a9a9a" stop-opacity="0.37"/>
            <stop offset="0.9835" stop-color="#757575" stop-opacity="0.19"/>
        </linearGradient>
        <linearGradient id="Gradient-124" x1="106.657" y1="148.824" x2="106.657" y2="187.463" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#9a9a9a" stop-opacity="0.37"/>
            <stop offset="0.9835" stop-color="#757575" stop-opacity="0.19"/>
        </linearGradient>
        <linearGradient id="Gradient-125" x1="110.596" y1="148.822" x2="110.596" y2="187.433" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#9a9a9a" stop-opacity="0.37"/>
            <stop offset="0.9835" stop-color="#757575" stop-opacity="0.19"/>
        </linearGradient>
        <linearGradient id="Gradient-126" x1="90.9614" y1="148.807" x2="90.9614" y2="187.573" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#9a9a9a" stop-opacity="0.37"/>
            <stop offset="0.9835" stop-color="#757575" stop-opacity="0.19"/>
        </linearGradient>
        <linearGradient id="Gradient-127" x1="94.8988" y1="148.805" x2="94.8988" y2="187.552" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#9a9a9a" stop-opacity="0.37"/>
            <stop offset="0.9835" stop-color="#757575" stop-opacity="0.19"/>
        </linearGradient>
        <linearGradient id="Gradient-128" x1="98.8125" y1="148.815" x2="98.8125" y2="187.448" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#9a9a9a" stop-opacity="0.37"/>
            <stop offset="0.9835" stop-color="#757575" stop-opacity="0.19"/>
        </linearGradient>
        <linearGradient id="Gradient-129" x1="102.745" y1="148.851" x2="102.745" y2="187.409" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#9a9a9a" stop-opacity="0.37"/>
            <stop offset="0.9835" stop-color="#757575" stop-opacity="0.19"/>
        </linearGradient>
        <linearGradient id="Gradient-130" x1="106.657" y1="148.772" x2="106.657" y2="187.627" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#9a9a9a" stop-opacity="0.37"/>
            <stop offset="0.9835" stop-color="#757575" stop-opacity="0.19"/>
        </linearGradient>
        <linearGradient id="Gradient-131" x1="110.596" y1="148.753" x2="110.596" y2="187.65" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#9a9a9a" stop-opacity="0.37"/>
            <stop offset="0.9835" stop-color="#757575" stop-opacity="0.19"/>
        </linearGradient>
        <linearGradient id="Gradient-132" x1="90.9614" y1="148.794" x2="90.9614" y2="187.649" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#9a9a9a" stop-opacity="0.37"/>
            <stop offset="0.9835" stop-color="#757575" stop-opacity="0.19"/>
        </linearGradient>
        <linearGradient id="Gradient-133" x1="94.8988" y1="148.786" x2="94.8988" y2="187.643" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#9a9a9a" stop-opacity="0.37"/>
            <stop offset="0.9835" stop-color="#757575" stop-opacity="0.19"/>
        </linearGradient>
        <linearGradient id="Gradient-134" x1="98.8125" y1="148.83" x2="98.8125" y2="187.493" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#9a9a9a" stop-opacity="0.37"/>
            <stop offset="0.9835" stop-color="#757575" stop-opacity="0.19"/>
        </linearGradient>
        <linearGradient id="Gradient-135" x1="102.745" y1="148.739" x2="102.745" y2="187.697" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#9a9a9a" stop-opacity="0.37"/>
            <stop offset="0.9835" stop-color="#757575" stop-opacity="0.19"/>
        </linearGradient>
        <linearGradient id="Gradient-136" x1="106.657" y1="148.863" x2="106.657" y2="187.405" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#9a9a9a" stop-opacity="0.37"/>
            <stop offset="0.9835" stop-color="#757575" stop-opacity="0.19"/>
        </linearGradient>
        <linearGradient id="Gradient-137" x1="110.596" y1="148.857" x2="110.596" y2="187.443" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#9a9a9a" stop-opacity="0.37"/>
            <stop offset="0.9835" stop-color="#757575" stop-opacity="0.19"/>
        </linearGradient>
        <linearGradient id="Gradient-138" x1="121.1" y1="141.187" x2="130.55" y2="136.599" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#333333"/>
            <stop offset="1" stop-color="#cccccc"/>
        </linearGradient>
        <linearGradient id="Gradient-139" x1="112.779" y1="136.903" x2="126.465" y2="136.903" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#9863db"/>
            <stop offset="1" stop-color="#cf94fc"/>
        </linearGradient>
        <linearGradient id="Gradient-140" x1="121.48" y1="140.011" x2="126.465" y2="140.011" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#2f006a"/>
            <stop offset="1" stop-color="#57138b"/>
        </linearGradient>
        <linearGradient id="Gradient-141" x1="112.779" y1="138.928" x2="121.48" y2="138.928" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#4c00ab"/>
            <stop offset="1" stop-color="#801ccc"/>
        </linearGradient>
        <linearGradient id="Gradient-142" x1="120.439" y1="138.814" x2="125.856" y2="136.185" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#333333"/>
            <stop offset="1" stop-color="#cccccc"/>
        </linearGradient>
        <linearGradient id="Gradient-143" x1="115.213" y1="136.143" x2="123.684" y2="136.143" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#9863db"/>
            <stop offset="1" stop-color="#cf94fc"/>
        </linearGradient>
        <linearGradient id="Gradient-144" x1="120.824" y1="138.288" x2="123.684" y2="138.288" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#2f006a"/>
            <stop offset="1" stop-color="#57138b"/>
        </linearGradient>
        <linearGradient id="Gradient-145" x1="115.213" y1="137.479" x2="120.824" y2="137.479" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#4c00ab"/>
            <stop offset="1" stop-color="#801ccc"/>
        </linearGradient>
        <linearGradient id="Gradient-146" x1="104.305" y1="140.359" x2="109.077" y2="139.914" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#333333"/>
            <stop offset="1" stop-color="#cccccc"/>
        </linearGradient>
        <linearGradient id="Gradient-147" x1="98.8691" y1="139.572" x2="105.584" y2="139.572" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#2f006a"/>
            <stop offset="1" stop-color="#57138b"/>
        </linearGradient>
        <linearGradient id="Gradient-148" x1="98.8691" y1="139.572" x2="105.584" y2="139.572" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#9863db"/>
            <stop offset="1" stop-color="#cf94fc"/>
        </linearGradient>
        <linearGradient id="Gradient-149" x1="98.847" y1="141.159" x2="105.61" y2="141.159" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#2f006a"/>
            <stop offset="1" stop-color="#57138b"/>
        </linearGradient>
        <linearGradient id="Gradient-150" x1="115.769" y1="146.662" x2="120.556" y2="146.216" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#333333"/>
            <stop offset="1" stop-color="#cccccc"/>
        </linearGradient>
        <linearGradient id="Gradient-151" x1="110.331" y1="145.876" x2="117.046" y2="145.876" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#2f006a"/>
            <stop offset="1" stop-color="#57138b"/>
        </linearGradient>
        <linearGradient id="Gradient-152" x1="110.331" y1="145.876" x2="117.046" y2="145.876" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#9863db"/>
            <stop offset="1" stop-color="#cf94fc"/>
        </linearGradient>
        <linearGradient id="Gradient-153" x1="110.316" y1="147.468" x2="117.06" y2="147.468" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#2f006a"/>
            <stop offset="1" stop-color="#57138b"/>
        </linearGradient>
        <linearGradient id="Gradient-154" x1="205.598" y1="114.833" x2="178.075" y2="166.897" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#9543ff"/>
            <stop offset="1" stop-color="#ffffff"/>
        </linearGradient>
        <linearGradient id="Gradient-155" x1="206.332" y1="106.441" x2="216.748" y2="175.734" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#9543ff"/>
            <stop offset="1" stop-color="#ffffff"/>
        </linearGradient>
        <linearGradient id="Gradient-156" x1="179.179" y1="107.674" x2="222.169" y2="107.674" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#9863db"/>
            <stop offset="1" stop-color="#cf94fc"/>
        </linearGradient>
        <clipPath id="ClipPath-1">
            <rect id="mask-01" width="51.8" height="5.296826088851958e-09" fill="url(#Gradient-29)" stroke="none" transform="translate(24.4,44.925) translate(-24.4,38.975)"/>
        </clipPath>
        <clipPath id="ClipPath-2">
            <rect id="mask-2" width="51.8" height="0" fill="url(#Gradient-29)" stroke="none" transform="translate(112.4,44.925) translate(-24.4,38.975)"/>
        </clipPath>
        <clipPath id="ClipPath-3">
            <rect id="mask-3" width="51.8" height="0" fill="url(#Gradient-29)" stroke="none" transform="translate(199.463,43.0779) translate(-24.4,38.975)"/>
        </clipPath>
        <clipPath id="ClipPath-4">
            <rect id="mask-4" width="51.8" height="0" fill="url(#Gradient-29)" stroke="none" transform="translate(24.4,142.825) translate(-24.4,38.975)"/>
        </clipPath>
        <clipPath id="ClipPath-5">
            <rect id="mask-5" width="51.8" height="0" fill="url(#Gradient-29)" stroke="none" transform="translate(111.347,146.051) translate(-24.4,38.975)"/>
        </clipPath>
        <clipPath id="ClipPath-6">
            <rect id="mask-6" width="51.8" height="0" fill="url(#Gradient-29)" stroke="none" transform="translate(197.3,138) translate(0,0) translate(-24.4,38.975)"/>
        </clipPath>
    </defs>
    <title/>
    <g id="building-01" opacity="1" clip-path="url(#ClipPath-1)" transform="translate(69.9,83.45) translate(-25.9,-43.3)">
        <g transform="translate(25.9,43.3) translate(-25.9,-43.3)">
            <path class="st0" d="M19.6,33.7L19.6,82.4L50.7,64.5L50.7,15.5L19.6,33.7Z" fill="url(#Gradient-0)" transform="translate(35.15,48.95) translate(-35.15,-48.95)"/>
            <g transform="translate(10.3,52.7) translate(-10.3,-52.7)">
                <path class="st1" d="M1.1,23L1.1,71.7L19.5,82.4L19.5,33.6L1.1,23Z" fill="url(#Gradient-1)" transform="translate(10.3,52.7) translate(-10.3,-52.7)"/>
            </g>
            <g transform="translate(25.9,73.65) translate(-25.9,-73.65)">
                <g transform="translate(35.7,72.85) translate(-35.7,-72.85)">
                    <path class="st2" d="M19.6,82.3L51.8,63.8L51.1,63.4L19.6,81.5Z" fill="#A9A3A3" opacity="0.5" transform="translate(35.7,72.85) translate(-35.7,-72.85)"/>
                </g>
                <path class="st3" d="M0,71.1L0.7,70.7L19.6,81.5L19.6,82.3Z" fill="#DAD4D4" opacity="0.5" transform="translate(9.8,76.5) translate(-9.8,-76.5)"/>
                <g transform="translate(35.7,73.85) translate(-35.7,-73.85)">
                    <path class="st4" d="M19.6,83.9L51.8,65.3L51.8,63.8L19.6,82.3Z" fill="url(#Gradient-2)" transform="translate(35.7,73.85) translate(-35.7,-73.85)"/>
                </g>
                <g transform="translate(9.8,77.5) translate(-9.8,-77.5)">
                    <path class="st5" d="M19.6,83.9L0,72.7L0,71.1L19.6,82.3Z" fill="url(#Gradient-3)" transform="translate(9.8,77.5) translate(-9.8,-77.5)"/>
                </g>
            </g>
            <g transform="translate(25.95,18.45) translate(-25.95,-18.45)">
                <path class="st6" d="M19.6,34.2L51.5,15.8L51.5,13.7L19.6,32.2Z" fill="url(#Gradient-4)" transform="translate(35.55,23.95) translate(-35.55,-23.95)"/>
                <path class="st7" d="M19.6,34.2L0.3,23.1L0.3,21.2L19.6,32.3Z" fill="url(#Gradient-5)" transform="translate(9.95,27.7) translate(-9.95,-27.7)"/>
                <g transform="translate(25.95,17.45) translate(-25.95,-17.45)">
                    <path class="st8" d="M32.5,2.7L0.3,21.2L19.6,32.2L51.6,13.7L32.5,2.7Z" fill="url(#Gradient-6)" transform="translate(25.95,17.45) translate(-25.95,-17.45)"/>
                </g>
                <g transform="translate(25.9,17.45) translate(-25.9,-17.45)">
                    <path class="st9" d="M32.3,3.9L2.4,21.2L19.5,31L49.4,13.7L32.3,3.9Z" fill="url(#Gradient-7)" transform="translate(25.9,17.45) translate(-25.9,-17.45)"/>
                </g>
                <g transform="translate(37.65,12.25) translate(-37.65,-12.25)">
                    <path class="st10" d="M38.5,12.1L37.6,16.2L42.4,16.3L45.4,14.7L39.1,11.1Z" fill="url(#Gradient-8)" opacity="0.33" transform="translate(41.5,13.7) translate(-41.5,-13.7)"/>
                    <g transform="translate(35.9,12.2) translate(-35.9,-12.2)">
                        <path class="st11" d="M37.5,15.2L29.9,10.7L34.2,8.2L41.9,12.6Z" fill="#A06AE0" transform="translate(35.9,11.7) translate(-35.9,-11.7)"/>
                        <path class="st12" d="M41.9,13.7L37.5,16.2L37.5,15.2L41.9,12.6Z" fill="#42097A" transform="translate(39.7,14.4) translate(-39.7,-14.4)"/>
                        <path class="st13" d="M37.5,16.2L29.9,11.8L29.9,10.7L37.5,15.2Z" fill="#6D12C0" transform="translate(33.7,13.45) translate(-33.7,-13.45)"/>
                    </g>
                    <path class="st14" d="M37.6,11.5L37,14.1L39.1,14L41.2,12.7L37.9,10.9Z" fill="url(#Gradient-9)" opacity="0.33" transform="translate(39.1,12.5) translate(-39.1,-12.5)"/>
                    <g transform="translate(35.75,11.5) translate(-35.75,-11.5)">
                        <path class="st11" d="M36.9,13.2L32,10.3L34.5,8.9L39.5,11.7Z" fill="#A06AE0" transform="translate(35.75,11.05) translate(-35.75,-11.05)"/>
                        <path class="st12" d="M39.5,12.6L36.9,14.1L36.9,13.2L39.5,11.7Z" fill="#42097A" transform="translate(38.2,12.9) translate(-38.2,-12.9)"/>
                        <path class="st13" d="M36.9,14.1L32,11.2L32,10.3L36.9,13.2Z" fill="#6D12C0" transform="translate(34.45,12.2) translate(-34.45,-12.2)"/>
                    </g>
                </g>
                <g transform="translate(26.25,14.1) translate(-26.25,-14.1)">
                    <path class="st15" d="M28.8,13.3C28.3,13,27.6,12.8,26.9,12.8C26.8,12.8,26.8,12.8,26.7,12.8L26.6,12.8L26.6,12.8L26.4,12.8C26.4,12.8,26.4,12.8,26.4,12.8L23.8,13.1L24.4,14.1C24.3,14.6,24.5,15,25.2,15.4L27,15.5L28.6,14.9C28.6,14.9,28.7,14.9,28.7,14.8C28.8,14.8,28.9,14.7,28.9,14.7C29.9,14.2,29.9,13.9,28.8,13.3Z" fill="url(#Gradient-10)" opacity="0.33" transform="translate(26.719,14.15) translate(-26.719,-14.15)"/>
                    <path class="st13" d="M27.3,12.8C26.3,12.2,24.6,12.2,23.6,12.8C22.6,13.4,22.6,14.3,23.7,14.9C24.7,15.5,26.4,15.5,27.4,14.9C28.4,14.4,28.4,13.4,27.3,12.8Z" fill="#6D12C0" transform="translate(25.5,13.85) translate(-25.5,-13.85)"/>
                    <path class="st11" d="M27.3,12.8C26.3,12.2,24.6,12.2,23.6,12.8C22.6,13.4,22.6,14.3,23.7,14.9C24.7,15.5,26.4,15.5,27.4,14.9C28.4,14.4,28.4,13.4,27.3,12.8ZM24.4,12.6L26.7,12.6C26.8,12.6,26.8,12.7,26.8,12.7C26.8,12.8,26.7,12.8,26.7,12.8L24.4,12.8C24.3,12.8,24.3,12.7,24.3,12.7C24.2,12.7,24.3,12.6,24.4,12.6ZM23.6,13.1L27.5,13.1C27.6,13.1,27.7,13.2,27.7,13.3C27.7,13.4,27.6,13.5,27.5,13.5L23.6,13.5C23.5,13.5,23.4,13.4,23.4,13.3C23.4,13.1,23.5,13.1,23.6,13.1ZM26.7,15.1L24.4,15.1C24.3,15.1,24.3,15,24.3,14.9C24.3,14.8,24.4,14.7,24.4,14.7L26.7,14.7C26.8,14.7,26.8,14.8,26.8,14.9C26.9,15.1,26.8,15.1,26.7,15.1ZM27.5,14.6L23.6,14.6C23.5,14.6,23.4,14.5,23.4,14.4C23.4,14.3,23.5,14.2,23.6,14.2L27.5,14.2C27.6,14.2,27.7,14.3,27.7,14.4C27.7,14.5,27.6,14.6,27.5,14.6ZM27.8,13.9L23.2,13.9C23.1,13.9,23,13.8,23,13.7C23,13.6,23.1,13.5,23.2,13.5L27.8,13.5C27.9,13.5,28,13.6,28,13.7C28,13.8,27.9,13.9,27.8,13.9Z" fill="#A06AE0" transform="translate(25.5,13.85) translate(-25.5,-13.85)"/>
                    <g transform="translate(25.55,14.825) translate(-25.55,-14.825)">
                        <path class="st12" d="M28.2,13.9L28.2,14.4C28.2,14.8,27.9,15.1,27.4,15.4C26.4,16,24.7,16,23.7,15.4C23.2,15.1,22.9,14.8,22.9,14.4L22.9,13.8C22.9,14.2,23.2,14.6,23.7,14.9C24.7,15.5,26.4,15.5,27.4,14.9C27.9,14.7,28.2,14.2,28.2,13.9Z" fill="#42097A" transform="translate(25.55,14.825) translate(-25.55,-14.825)"/>
                    </g>
                </g>
                <g transform="translate(19.4009,17.6) translate(-19.4009,-17.6)">
                    <path class="st16" d="M22,16.8C21.5,16.5,20.8,16.3,20.1,16.3C20,16.3,20,16.3,19.9,16.3L19.8,16.3L19.8,16.3L19.6,16.3C19.6,16.3,19.6,16.3,19.6,16.3L17,16.7L17.6,17.7C17.5,18.2,17.7,18.6,18.4,19L20.2,19.1L21.8,18.5C21.8,18.5,21.9,18.5,21.9,18.4C22,18.4,22.1,18.3,22.1,18.3C23.1,17.7,23,17.4,22,16.8Z" fill="url(#Gradient-11)" opacity="0.33" transform="translate(19.9009,17.7) translate(-19.9009,-17.7)"/>
                    <path class="st13" d="M20.5,16.3C19.5,15.7,17.8,15.7,16.8,16.3C15.8,16.9,15.8,17.8,16.9,18.5C17.9,19.1,19.6,19.1,20.6,18.5C21.6,17.9,21.6,16.9,20.5,16.3Z" fill="#6D12C0" transform="translate(18.7,17.4) translate(-18.7,-17.4)"/>
                    <path class="st11" d="M20.5,16.3C19.5,15.7,17.8,15.7,16.8,16.3C15.8,16.9,15.8,17.8,16.9,18.5C17.9,19.1,19.6,19.1,20.6,18.5C21.6,17.9,21.6,16.9,20.5,16.3ZM17.5,16.1L19.8,16.1C19.9,16.1,19.9,16.2,19.9,16.2C19.9,16.3,19.8,16.3,19.8,16.3L17.5,16.3C17.4,16.3,17.4,16.2,17.4,16.2C17.4,16.2,17.5,16.1,17.5,16.1ZM16.8,16.6L20.7,16.6C20.8,16.6,20.9,16.7,20.9,16.8C20.9,16.9,20.8,17,20.7,17L16.8,17C16.7,17,16.6,16.9,16.6,16.8C16.6,16.7,16.7,16.6,16.8,16.6ZM19.9,18.7L17.6,18.7C17.5,18.7,17.5,18.6,17.5,18.5C17.5,18.4,17.6,18.3,17.6,18.3L19.9,18.3C20,18.3,20,18.4,20,18.5C20.1,18.6,20,18.7,19.9,18.7ZM20.7,18.1L16.8,18.1C16.7,18.1,16.6,18,16.6,17.9C16.6,17.8,16.7,17.7,16.8,17.7L20.7,17.7C20.8,17.7,20.9,17.8,20.9,17.9C20.9,18,20.8,18.1,20.7,18.1ZM21,17.5L16.4,17.5C16.3,17.5,16.2,17.4,16.2,17.3C16.2,17.2,16.3,17.1,16.4,17.1L21,17.1C21.1,17.1,21.2,17.2,21.2,17.3C21.2,17.4,21.1,17.5,21,17.5Z" fill="#A06AE0" transform="translate(18.7,17.4) translate(-18.7,-17.4)"/>
                    <g transform="translate(18.65,18.325) translate(-18.65,-18.325)">
                        <path class="st12" d="M21.3,17.4L21.3,17.9C21.3,18.3,21,18.6,20.5,18.9C19.5,19.5,17.8,19.5,16.8,18.9C16.3,18.6,16,18.3,16,17.9L16,17.3C16,17.7,16.3,18.1,16.8,18.4C17.8,19,19.5,19,20.5,18.4C21.1,18.2,21.3,17.8,21.3,17.4Z" fill="#42097A" transform="translate(18.65,18.325) translate(-18.65,-18.325)"/>
                    </g>
                </g>
                <g transform="translate(12.369,21.4) translate(-12.369,-21.4)">
                    <path class="st17" d="M14.9,20.6C14.4,20.3,13.7,20.1,13,20.1C12.9,20.1,12.9,20.1,12.8,20.1L12.7,20.1L12.7,20.1L12.5,20.1C12.5,20.1,12.5,20.1,12.5,20.1L9.9,20.4L10.5,21.4C10.4,21.9,10.6,22.3,11.3,22.7L13.1,22.8L14.7,22.2C14.7,22.2,14.8,22.2,14.8,22.1C14.9,22.1,15,22,15,22C16,21.5,16,21.2,14.9,20.6Z" fill="url(#Gradient-12)" opacity="0.33" transform="translate(12.819,21.45) translate(-12.819,-21.45)"/>
                    <path class="st13" d="M13.5,20.1C12.5,19.5,10.8,19.5,9.8,20.1C8.8,20.7,8.8,21.7,9.9,22.3C10.9,22.9,12.6,22.9,13.6,22.3C14.5,21.7,14.5,20.7,13.5,20.1Z" fill="#6D12C0" transform="translate(11.6625,21.2) translate(-11.6625,-21.2)"/>
                    <path class="st11" d="M13.5,20.1C12.5,19.5,10.8,19.5,9.8,20.1C8.8,20.7,8.8,21.7,9.9,22.3C10.9,22.9,12.6,22.9,13.6,22.3C14.5,21.7,14.5,20.7,13.5,20.1ZM10.5,19.9L12.8,19.9C12.9,19.9,12.9,20,12.9,20C12.9,20.1,12.8,20.1,12.8,20.1L10.5,20.1C10.4,20.1,10.4,20,10.4,20C10.3,20,10.4,19.9,10.5,19.9ZM9.7,20.3L13.6,20.3C13.7,20.3,13.8,20.4,13.8,20.5C13.8,20.6,13.7,20.7,13.6,20.7L9.7,20.7C9.6,20.7,9.5,20.6,9.5,20.5C9.5,20.4,9.6,20.3,9.7,20.3ZM12.9,22.4L10.6,22.4C10.5,22.4,10.5,22.3,10.5,22.2C10.5,22.1,10.6,22,10.6,22L12.9,22C13,22,13,22.1,13,22.2C13,22.4,12.9,22.4,12.9,22.4ZM13.6,21.9L9.7,21.9C9.6,21.9,9.5,21.8,9.5,21.7C9.5,21.6,9.6,21.5,9.7,21.5L13.6,21.5C13.7,21.5,13.8,21.6,13.8,21.7C13.8,21.8,13.7,21.9,13.6,21.9ZM14,21.2L9.3,21.2C9.2,21.2,9.1,21.1,9.1,21C9.1,20.9,9.2,20.8,9.3,20.8L14,20.8C14.1,20.8,14.2,20.9,14.2,21C14.1,21.1,14.1,21.2,14,21.2Z" fill="#A06AE0" transform="translate(11.6625,21.2) translate(-11.6625,-21.2)"/>
                    <g transform="translate(11.65,22.125) translate(-11.65,-22.125)">
                        <path class="st12" d="M14.3,21.2L14.3,21.7C14.3,22.1,14,22.4,13.5,22.7C12.5,23.3,10.8,23.3,9.8,22.7C9.3,22.4,9,22,9,21.7L9,21.1C9,21.5,9.3,21.9,9.8,22.2C10.8,22.8,12.5,22.8,13.5,22.2C14,22,14.3,21.5,14.3,21.2Z" fill="#42097A" transform="translate(11.65,22.125) translate(-11.65,-22.125)"/>
                    </g>
                </g>
            </g>
            <g transform="translate(25.9,68.05) translate(-25.9,-68.05)">
                <g transform="translate(35.7,67.3) translate(-35.7,-67.3)">
                    <path class="st2" d="M19.6,76.8L51.8,58.2L51.1,57.8L19.6,75.9Z" fill="#A9A3A3" opacity="0.5" transform="translate(35.7,67.3) translate(-35.7,-67.3)"/>
                </g>
                <path class="st3" d="M0,65.6L0.7,65.1L19.6,75.9L19.6,76.8Z" fill="#DAD4D4" opacity="0.5" transform="translate(9.8,70.95) translate(-9.8,-70.95)"/>
                <g transform="translate(35.7,68.25) translate(-35.7,-68.25)">
                    <path class="st18" d="M19.6,78.3L51.8,59.8L51.8,58.2L19.6,76.8Z" fill="url(#Gradient-13)" transform="translate(35.7,68.25) translate(-35.7,-68.25)"/>
                </g>
                <g transform="translate(9.8,71.9) translate(-9.8,-71.9)">
                    <path class="st19" d="M19.6,78.3L0,67.1L0,65.5L19.6,76.8Z" fill="url(#Gradient-14)" transform="translate(9.8,71.9) translate(-9.8,-71.9)"/>
                </g>
            </g>
            <g transform="translate(25.9,62.55) translate(-25.9,-62.55)">
                <g transform="translate(35.7,61.75) translate(-35.7,-61.75)">
                    <path class="st2" d="M19.6,71.2L51.8,52.7L51.1,52.3L19.6,70.4Z" fill="#A9A3A3" opacity="0.5" transform="translate(35.7,61.75) translate(-35.7,-61.75)"/>
                </g>
                <path class="st3" d="M0,60L0.7,59.6L19.6,70.4L19.6,71.2Z" fill="#DAD4D4" opacity="0.5" transform="translate(9.8,65.4) translate(-9.8,-65.4)"/>
                <g transform="translate(35.7,62.75) translate(-35.7,-62.75)">
                    <path class="st20" d="M19.6,72.8L51.8,54.2L51.8,52.7L19.6,71.2Z" fill="url(#Gradient-15)" transform="translate(35.7,62.75) translate(-35.7,-62.75)"/>
                </g>
                <g transform="translate(9.8,66.4) translate(-9.8,-66.4)">
                    <path class="st21" d="M19.6,72.8L0,61.5L0,60L19.6,71.2Z" fill="url(#Gradient-16)" transform="translate(9.8,66.4) translate(-9.8,-66.4)"/>
                </g>
            </g>
            <g transform="translate(25.9,56.95) translate(-25.9,-56.95)">
                <g transform="translate(35.7,56.15) translate(-35.7,-56.15)">
                    <path class="st2" d="M19.6,65.6L51.8,47.1L51.1,46.7L19.6,64.8Z" fill="#A9A3A3" opacity="0.5" transform="translate(35.7,56.15) translate(-35.7,-56.15)"/>
                </g>
                <path class="st3" d="M0,54.4L0.7,54L19.6,64.8L19.6,65.6Z" fill="#DAD4D4" opacity="0.5" transform="translate(9.8,59.8) translate(-9.8,-59.8)"/>
                <g transform="translate(35.7,57.15) translate(-35.7,-57.15)">
                    <path class="st22" d="M19.6,67.2L51.8,48.7L51.8,47.1L19.6,65.6Z" fill="url(#Gradient-17)" transform="translate(35.7,57.15) translate(-35.7,-57.15)"/>
                </g>
                <g transform="translate(9.8,60.8) translate(-9.8,-60.8)">
                    <path class="st23" d="M19.6,67.2L0,56L0,54.4L19.6,65.6Z" fill="url(#Gradient-18)" transform="translate(9.8,60.8) translate(-9.8,-60.8)"/>
                </g>
            </g>
            <g transform="translate(25.9,51.4) translate(-25.9,-51.4)">
                <g transform="translate(35.7,50.6) translate(-35.7,-50.6)">
                    <path class="st2" d="M19.6,60.1L51.8,41.5L51.1,41.1L19.6,59.3Z" fill="#A9A3A3" opacity="0.5" transform="translate(35.7,50.6) translate(-35.7,-50.6)"/>
                </g>
                <path class="st3" d="M0,48.9L0.7,48.5L19.6,59.3L19.6,60.1Z" fill="#DAD4D4" opacity="0.5" transform="translate(9.8,54.3) translate(-9.8,-54.3)"/>
                <g transform="translate(35.7,51.6) translate(-35.7,-51.6)">
                    <path class="st24" d="M19.6,61.7L51.8,43.1L51.8,41.5L19.6,60.1Z" fill="url(#Gradient-19)" transform="translate(35.7,51.6) translate(-35.7,-51.6)"/>
                </g>
                <g transform="translate(9.8,55.3) translate(-9.8,-55.3)">
                    <path class="st25" d="M19.6,61.7L0,50.4L0,48.9L19.6,60.1Z" fill="url(#Gradient-20)" transform="translate(9.8,55.3) translate(-9.8,-55.3)"/>
                </g>
            </g>
            <g transform="translate(25.9,45.85) translate(-25.9,-45.85)">
                <g transform="translate(35.7,45.05) translate(-35.7,-45.05)">
                    <path class="st2" d="M19.6,54.5L51.8,36L51.1,35.6L19.6,53.7Z" fill="#A9A3A3" opacity="0.5" transform="translate(35.7,45.05) translate(-35.7,-45.05)"/>
                </g>
                <path class="st3" d="M0,43.3L0.7,42.9L19.6,53.7L19.6,54.5Z" fill="#DAD4D4" opacity="0.5" transform="translate(9.8,48.7) translate(-9.8,-48.7)"/>
                <g transform="translate(35.7,46.05) translate(-35.7,-46.05)">
                    <path class="st26" d="M19.6,56.1L51.8,37.5L51.8,36L19.6,54.5Z" fill="url(#Gradient-21)" transform="translate(35.7,46.05) translate(-35.7,-46.05)"/>
                </g>
                <g transform="translate(9.8,49.7) translate(-9.8,-49.7)">
                    <path class="st27" d="M19.6,56.1L0,44.9L0,43.3L19.6,54.5Z" fill="url(#Gradient-22)" transform="translate(9.8,49.7) translate(-9.8,-49.7)"/>
                </g>
            </g>
            <g transform="translate(25.9,40.25) translate(-25.9,-40.25)">
                <g transform="translate(35.7,39.5) translate(-35.7,-39.5)">
                    <path class="st2" d="M19.6,49L51.8,30.4L51.1,30L19.6,48.1Z" fill="#A9A3A3" opacity="0.5" transform="translate(35.7,39.5) translate(-35.7,-39.5)"/>
                </g>
                <path class="st3" d="M0,37.8L0.7,37.3L19.6,48.1L19.6,49Z" fill="#DAD4D4" opacity="0.5" transform="translate(9.8,43.15) translate(-9.8,-43.15)"/>
                <g transform="translate(35.7,40.45) translate(-35.7,-40.45)">
                    <path class="st28" d="M19.6,50.5L51.8,32L51.8,30.4L19.6,49Z" fill="url(#Gradient-23)" transform="translate(35.7,40.45) translate(-35.7,-40.45)"/>
                </g>
                <g transform="translate(9.8,44.1) translate(-9.8,-44.1)">
                    <path class="st29" d="M19.6,50.5L0,39.3L0,37.7L19.6,49Z" fill="url(#Gradient-24)" transform="translate(9.8,44.1) translate(-9.8,-44.1)"/>
                </g>
            </g>
            <g transform="translate(25.9,34.75) translate(-25.9,-34.75)">
                <g transform="translate(35.7,33.95) translate(-35.7,-33.95)">
                    <path class="st2" d="M19.6,43.4L51.8,24.9L51.1,24.5L19.6,42.6Z" fill="#A9A3A3" opacity="0.5" transform="translate(35.7,33.95) translate(-35.7,-33.95)"/>
                </g>
                <path class="st3" d="M0,32.2L0.7,31.8L19.6,42.6L19.6,43.4Z" fill="#DAD4D4" opacity="0.5" transform="translate(9.8,37.6) translate(-9.8,-37.6)"/>
                <g transform="translate(35.7,34.95) translate(-35.7,-34.95)">
                    <path class="st30" d="M19.6,45L51.8,26.4L51.8,24.9L19.6,43.4Z" fill="url(#Gradient-25)" transform="translate(35.7,34.95) translate(-35.7,-34.95)"/>
                </g>
                <g transform="translate(9.8,38.6) translate(-9.8,-38.6)">
                    <path class="st31" d="M19.6,45L0,33.7L0,32.2L19.6,43.4Z" fill="url(#Gradient-26)" transform="translate(9.8,38.6) translate(-9.8,-38.6)"/>
                </g>
            </g>
            <g transform="translate(25.9,29.15) translate(-25.9,-29.15)">
                <g transform="translate(35.7,28.4) translate(-35.7,-28.4)">
                    <path class="st2" d="M19.6,37.9L51.8,19.3L51.1,18.9L19.6,37Z" fill="#A9A3A3" opacity="0.5" transform="translate(35.7,28.4) translate(-35.7,-28.4)"/>
                </g>
                <path class="st3" d="M0,26.6L0.7,26.2L19.6,37L19.6,37.9Z" fill="#DAD4D4" opacity="0.5" transform="translate(9.8,32.05) translate(-9.8,-32.05)"/>
                <g transform="translate(35.7,29.35) translate(-35.7,-29.35)">
                    <path class="st32" d="M19.6,39.4L51.8,20.9L51.8,19.3L19.6,37.9Z" fill="url(#Gradient-27)" transform="translate(35.7,29.35) translate(-35.7,-29.35)"/>
                </g>
                <g transform="translate(9.8,33) translate(-9.8,-33)">
                    <path class="st33" d="M19.6,39.4L0,28.2L0,26.6L19.6,37.9Z" fill="url(#Gradient-28)" transform="translate(9.8,33) translate(-9.8,-33)"/>
                </g>
            </g>
        </g>
    </g>
    <g id="building-02" opacity="1" clip-path="url(#ClipPath-2)" transform="translate(157.75,85.85) translate(-113.75,-45.7)">
        <path class="st90" d="M133.8,63.7L111.1,77.2L111.1,37.7L133.8,24.3Z" fill="url(#Gradient-30)" transform="translate(122.45,50.75) translate(-122.45,-50.75)"/>
        <path class="st91" d="M111.1,77.1L93.7,67.1L93.7,27.7L111.1,37.7Z" fill="url(#Gradient-31)" transform="translate(102.4,52.4) translate(-102.4,-52.4)"/>
        <path class="st92" d="M111.1,37.7L93.7,27.7L116.4,14.2L133.8,24.3Z" fill="url(#Gradient-32)" transform="translate(113.75,25.95) translate(-113.75,-25.95)"/>
    </g>
    <g id="building-03" opacity="1" clip-path="url(#ClipPath-3)" transform="translate(240.848,81.3) translate(-196.848,-41.15)">
        <g transform="translate(198.65,40.75) translate(-198.65,-40.75)">
            <g transform="translate(198.65,40.75) translate(-198.65,-40.75)">
                <path class="st93" d="M181.5,69.9L201.2,81.5L201.2,19.8L181.5,8.3Z" fill="url(#Gradient-33)" transform="translate(191.35,44.9) translate(-191.35,-44.9)"/>
                <path class="st94" d="M201.2,81.5L215.8,73.1L215.8,11.4L201.2,19.8Z" fill="url(#Gradient-34)" transform="translate(208.5,46.45) translate(-208.5,-46.45)"/>
                <g transform="translate(208.75,44.95) translate(-208.75,-44.95)">
                    <g transform="translate(208.75,44.95) translate(-208.75,-44.95)">
                        <g transform="translate(208.75,44.95) translate(-208.75,-44.95)">
                            <path class="st95" d="M214.8,15.9L214.8,67.2L202.7,74L202.7,22.9Z" fill="url(#Gradient-35)" transform="translate(208.75,44.95) translate(-208.75,-44.95)"/>
                        </g>
                    </g>
                </g>
                <g transform="translate(208.75,45.05) translate(-208.75,-45.05)">
                    <g transform="translate(208.75,67.7) translate(-208.75,-67.7)">
                        <path class="st96" d="M214.8,64.1L214.8,64.4L202.7,71.3L202.7,70.9Z" fill="url(#Gradient-36)" transform="translate(208.75,67.7) translate(-208.75,-67.7)"/>
                    </g>
                    <g transform="translate(208.75,63.7) translate(-208.75,-63.7)">
                        <path class="st97" d="M214.8,60.1L214.8,60.4L202.7,67.3L202.7,66.9Z" fill="url(#Gradient-37)" transform="translate(208.75,63.7) translate(-208.75,-63.7)"/>
                    </g>
                    <g transform="translate(208.75,59.7) translate(-208.75,-59.7)">
                        <path class="st98" d="M214.8,56.1L214.8,56.4L202.7,63.3L202.7,62.9Z" fill="url(#Gradient-38)" transform="translate(208.75,59.7) translate(-208.75,-59.7)"/>
                    </g>
                    <g transform="translate(208.75,55.75) translate(-208.75,-55.75)">
                        <path class="st99" d="M214.8,52.1L214.8,52.4L202.7,59.4L202.7,58.9Z" fill="url(#Gradient-39)" transform="translate(208.75,55.75) translate(-208.75,-55.75)"/>
                    </g>
                    <g transform="translate(208.75,51.75) translate(-208.75,-51.75)">
                        <path class="st100" d="M214.8,48.1L214.8,48.4L202.7,55.4L202.7,54.9Z" fill="url(#Gradient-40)" transform="translate(208.75,51.75) translate(-208.75,-51.75)"/>
                    </g>
                    <g transform="translate(208.75,47.75) translate(-208.75,-47.75)">
                        <path class="st101" d="M214.8,44.1L214.8,44.4L202.7,51.4L202.7,50.9Z" fill="url(#Gradient-41)" transform="translate(208.75,47.75) translate(-208.75,-47.75)"/>
                    </g>
                    <g transform="translate(208.75,43.75) translate(-208.75,-43.75)">
                        <path class="st102" d="M214.8,40.1L214.8,40.4L202.7,47.4L202.7,46.9Z" fill="url(#Gradient-42)" transform="translate(208.75,43.75) translate(-208.75,-43.75)"/>
                    </g>
                    <g transform="translate(208.75,39.75) translate(-208.75,-39.75)">
                        <path class="st103" d="M214.8,36.1L214.8,36.4L202.7,43.4L202.7,42.9Z" fill="url(#Gradient-43)" transform="translate(208.75,39.75) translate(-208.75,-39.75)"/>
                    </g>
                    <g transform="translate(208.75,35.75) translate(-208.75,-35.75)">
                        <path class="st104" d="M214.8,32.1L214.8,32.4L202.7,39.4L202.7,39Z" fill="url(#Gradient-44)" transform="translate(208.75,35.75) translate(-208.75,-35.75)"/>
                    </g>
                    <g transform="translate(208.75,31.75) translate(-208.75,-31.75)">
                        <path class="st105" d="M214.8,28.1L214.8,28.4L202.7,35.4L202.7,35Z" fill="url(#Gradient-45)" transform="translate(208.75,31.75) translate(-208.75,-31.75)"/>
                    </g>
                    <g transform="translate(203.95,47.75) translate(-203.95,-47.75)">
                        <path class="st106" d="M204.1,22.1L204.1,73.3L203.8,73.4L203.8,22.3Z" fill="url(#Gradient-46)" transform="translate(203.95,47.75) translate(-203.95,-47.75)"/>
                    </g>
                    <g transform="translate(205.25,46.95) translate(-205.25,-46.95)">
                        <path class="st107" d="M205.4,21.3L205.4,72.5L205.1,72.6L205.1,21.5Z" fill="url(#Gradient-47)" transform="translate(205.25,46.95) translate(-205.25,-46.95)"/>
                    </g>
                    <g transform="translate(206.65,46.2) translate(-206.65,-46.2)">
                        <path class="st108" d="M206.8,20.5L206.8,71.7L206.5,71.9L206.5,20.7Z" fill="url(#Gradient-48)" transform="translate(206.65,46.2) translate(-206.65,-46.2)"/>
                    </g>
                    <g transform="translate(207.95,45.45) translate(-207.95,-45.45)">
                        <path class="st109" d="M208.1,19.8L208.1,70.9L207.8,71.1L207.8,19.9Z" fill="url(#Gradient-49)" transform="translate(207.95,45.45) translate(-207.95,-45.45)"/>
                    </g>
                    <g transform="translate(209.35,44.65) translate(-209.35,-44.65)">
                        <path class="st110" d="M209.5,19L209.5,70.2L209.2,70.3L209.2,19.2Z" fill="url(#Gradient-50)" transform="translate(209.35,44.65) translate(-209.35,-44.65)"/>
                    </g>
                    <g transform="translate(210.65,43.85) translate(-210.65,-43.85)">
                        <path class="st111" d="M210.8,18.2L210.8,69.4L210.5,69.5L210.5,18.4Z" fill="url(#Gradient-51)" transform="translate(210.65,43.85) translate(-210.65,-43.85)"/>
                    </g>
                    <g transform="translate(212.05,43.1) translate(-212.05,-43.1)">
                        <path class="st112" d="M212.2,17.4L212.2,68.6L211.9,68.8L211.9,17.6Z" fill="url(#Gradient-52)" transform="translate(212.05,43.1) translate(-212.05,-43.1)"/>
                    </g>
                    <g transform="translate(213.35,42.35) translate(-213.35,-42.35)">
                        <path class="st113" d="M213.5,16.7L213.5,67.8L213.2,68L213.2,16.8Z" fill="url(#Gradient-53)" transform="translate(213.35,42.35) translate(-213.35,-42.35)"/>
                    </g>
                </g>
                <g transform="translate(191.05,43.4) translate(-191.05,-43.4)">
                    <path class="st114" d="M199.8,22.9L199.8,74L182.3,64L182.3,12.8Z" fill="url(#Gradient-54)" transform="translate(191.05,43.4) translate(-191.05,-43.4)"/>
                </g>
                <g transform="translate(198.55,43.05) translate(-198.55,-43.05)">
                    <g transform="translate(191.05,66.15) translate(-191.05,-66.15)">
                        <path class="st115" d="M199.8,70.9L199.8,71.3L182.3,61.3L182.3,61Z" fill="url(#Gradient-55)" transform="translate(191.05,66.15) translate(-191.05,-66.15)"/>
                    </g>
                    <g transform="translate(191.05,62.15) translate(-191.05,-62.15)">
                        <path class="st116" d="M199.8,66.9L199.8,67.3L182.3,57.3L182.3,57Z" fill="url(#Gradient-56)" transform="translate(191.05,62.15) translate(-191.05,-62.15)"/>
                    </g>
                    <g transform="translate(191.05,58.15) translate(-191.05,-58.15)">
                        <path class="st117" d="M199.8,62.9L199.8,63.3L182.3,53.3L182.3,53Z" fill="url(#Gradient-57)" transform="translate(191.05,58.15) translate(-191.05,-58.15)"/>
                    </g>
                    <g transform="translate(191.05,54.2) translate(-191.05,-54.2)">
                        <path class="st118" d="M199.8,58.9L199.8,59.4L182.3,49.3L182.3,49Z" fill="url(#Gradient-58)" transform="translate(191.05,54.2) translate(-191.05,-54.2)"/>
                    </g>
                    <g transform="translate(191.05,50.2) translate(-191.05,-50.2)">
                        <path class="st119" d="M199.8,54.9L199.8,55.4L182.3,45.3L182.3,45Z" fill="url(#Gradient-59)" transform="translate(191.05,50.2) translate(-191.05,-50.2)"/>
                    </g>
                    <g transform="translate(191.05,46.2) translate(-191.05,-46.2)">
                        <path class="st120" d="M199.8,50.9L199.8,51.4L182.3,41.3L182.3,41Z" fill="url(#Gradient-60)" transform="translate(191.05,46.2) translate(-191.05,-46.2)"/>
                    </g>
                    <g transform="translate(191.05,42.2) translate(-191.05,-42.2)">
                        <path class="st121" d="M199.8,46.9L199.8,47.4L182.3,37.3L182.3,37Z" fill="url(#Gradient-61)" transform="translate(191.05,42.2) translate(-191.05,-42.2)"/>
                    </g>
                    <g transform="translate(191.05,38.2) translate(-191.05,-38.2)">
                        <path class="st122" d="M199.8,42.9L199.8,43.4L182.3,33.3L182.3,33Z" fill="url(#Gradient-62)" transform="translate(191.05,38.2) translate(-191.05,-38.2)"/>
                    </g>
                    <g transform="translate(191.05,34.2) translate(-191.05,-34.2)">
                        <path class="st123" d="M199.8,39L199.8,39.4L182.3,29.3L182.3,29Z" fill="url(#Gradient-63)" transform="translate(191.05,34.2) translate(-191.05,-34.2)"/>
                    </g>
                    <g transform="translate(191.05,30.2) translate(-191.05,-30.2)">
                        <path class="st124" d="M199.8,35L199.8,35.4L182.3,25.3L182.3,25Z" fill="url(#Gradient-64)" transform="translate(191.05,30.2) translate(-191.05,-30.2)"/>
                    </g>
                    <g transform="translate(208.75,27.9) translate(-208.75,-27.9)">
                        <path class="st125" d="M214.8,24.3L214.8,24.6L202.7,31.5L202.7,31.1Z" fill="url(#Gradient-65)" transform="translate(208.75,27.9) translate(-208.75,-27.9)"/>
                    </g>
                    <g transform="translate(191.05,26.35) translate(-191.05,-26.35)">
                        <path class="st126" d="M199.8,31.1L199.8,31.5L182.3,21.5L182.3,21.2Z" fill="url(#Gradient-66)" transform="translate(191.05,26.35) translate(-191.05,-26.35)"/>
                    </g>
                    <g transform="translate(208.75,23.8) translate(-208.75,-23.8)">
                        <path class="st127" d="M214.8,20.2L214.8,20.5L202.7,27.4L202.7,27Z" fill="url(#Gradient-67)" transform="translate(208.75,23.8) translate(-208.75,-23.8)"/>
                    </g>
                    <g transform="translate(191.05,22.25) translate(-191.05,-22.25)">
                        <path class="st128" d="M199.8,27L199.8,27.4L182.3,17.4L182.3,17.1Z" fill="url(#Gradient-68)" transform="translate(191.05,22.25) translate(-191.05,-22.25)"/>
                    </g>
                    <g transform="translate(208.75,19.45) translate(-208.75,-19.45)">
                        <path class="st129" d="M214.8,15.8L214.8,16.1L202.7,23.1L202.7,22.7Z" fill="url(#Gradient-69)" transform="translate(208.75,19.45) translate(-208.75,-19.45)"/>
                    </g>
                    <g transform="translate(191.05,17.9) translate(-191.05,-17.9)">
                        <path class="st130" d="M199.8,22.7L199.8,23.1L182.3,13L182.3,12.7Z" fill="url(#Gradient-70)" transform="translate(191.05,17.9) translate(-191.05,-17.9)"/>
                    </g>
                    <g transform="translate(198.55,47.75) translate(-198.55,-47.75)">
                        <path class="st131" d="M198.7,22.3L198.7,73.4L198.4,73.3L198.4,22.1Z" fill="url(#Gradient-71)" transform="translate(198.55,47.75) translate(-198.55,-47.75)"/>
                    </g>
                    <g transform="translate(197.2,46.95) translate(-197.2,-46.95)">
                        <path class="st132" d="M197.3,21.5L197.3,72.6L197.1,72.5L197.1,21.3Z" fill="url(#Gradient-72)" transform="translate(197.2,46.95) translate(-197.2,-46.95)"/>
                    </g>
                    <g transform="translate(195.85,46.2) translate(-195.85,-46.2)">
                        <path class="st133" d="M196,20.7L196,71.9L195.7,71.7L195.7,20.5Z" fill="url(#Gradient-73)" transform="translate(195.85,46.2) translate(-195.85,-46.2)"/>
                    </g>
                    <g transform="translate(194.45,45.45) translate(-194.45,-45.45)">
                        <path class="st134" d="M194.6,19.9L194.6,71.1L194.3,70.9L194.3,19.8Z" fill="url(#Gradient-74)" transform="translate(194.45,45.45) translate(-194.45,-45.45)"/>
                    </g>
                    <g transform="translate(193.15,44.65) translate(-193.15,-44.65)">
                        <path class="st135" d="M193.3,19.2L193.3,70.3L193,70.2L193,19Z" fill="url(#Gradient-75)" transform="translate(193.15,44.65) translate(-193.15,-44.65)"/>
                    </g>
                    <g transform="translate(191.75,43.85) translate(-191.75,-43.85)">
                        <path class="st136" d="M191.9,18.4L191.9,69.5L191.6,69.4L191.6,18.2Z" fill="url(#Gradient-76)" transform="translate(191.75,43.85) translate(-191.75,-43.85)"/>
                    </g>
                    <g transform="translate(190.45,43.1) translate(-190.45,-43.1)">
                        <path class="st137" d="M190.6,17.6L190.6,68.8L190.3,68.6L190.3,17.4Z" fill="url(#Gradient-77)" transform="translate(190.45,43.1) translate(-190.45,-43.1)"/>
                    </g>
                    <g transform="translate(189.05,42.35) translate(-189.05,-42.35)">
                        <path class="st138" d="M189.2,16.8L189.2,68L188.9,67.8L188.9,16.7Z" fill="url(#Gradient-78)" transform="translate(189.05,42.35) translate(-189.05,-42.35)"/>
                    </g>
                    <g transform="translate(187.75,41.55) translate(-187.75,-41.55)">
                        <path class="st139" d="M187.9,16.1L187.9,67.2L187.6,67.1L187.6,15.9Z" fill="url(#Gradient-79)" transform="translate(187.75,41.55) translate(-187.75,-41.55)"/>
                    </g>
                    <g transform="translate(186.35,40.75) translate(-186.35,-40.75)">
                        <path class="st140" d="M186.5,15.3L186.5,66.4L186.2,66.3L186.2,15.1Z" fill="url(#Gradient-80)" transform="translate(186.35,40.75) translate(-186.35,-40.75)"/>
                    </g>
                    <g transform="translate(185.05,40) translate(-185.05,-40)">
                        <path class="st141" d="M185.2,14.5L185.2,65.7L184.9,65.5L184.9,14.3Z" fill="url(#Gradient-81)" transform="translate(185.05,40) translate(-185.05,-40)"/>
                    </g>
                    <g transform="translate(183.65,39.25) translate(-183.65,-39.25)">
                        <path class="st142" d="M183.8,13.7L183.8,64.9L183.5,64.7L183.5,13.6Z" fill="url(#Gradient-82)" transform="translate(183.65,39.25) translate(-183.65,-39.25)"/>
                    </g>
                </g>
                <path class="st143" d="M181.5,8.3L195.9,0L215.8,11.5L201.3,19.8Z" fill="#5442BA" transform="translate(198.65,9.9) translate(-198.65,-9.9)"/>
                <path class="st144" d="M195.9,1.5L183.9,8.4L201.2,18.4L213.2,11.5L195.9,1.5Z" fill="#362A77" transform="translate(198.55,9.95) translate(-198.55,-9.95)"/>
                <g transform="translate(204.701,10.45) translate(-204.701,-10.45)">
                    <path class="st145" d="M207,9.8C206.5,9.5,205.9,9.4,205.3,9.4C205.3,9.4,205.2,9.4,205.2,9.4L205.2,9.4L205.2,9.4L205,9.4C205,9.4,205,9.4,205,9.4L202.7,9.6L203.2,10.5C203.1,10.9,203.3,11.3,203.9,11.7L205.5,11.8L206.9,11.3C206.9,11.3,207,11.3,207,11.2C207.1,11.2,207.1,11.1,207.2,11.1C207.9,10.6,207.9,10.3,207,9.8Z" fill="url(#Gradient-83)" opacity="0.33" transform="translate(205.201,10.6) translate(-205.201,-10.6)"/>
                    <path class="st146" d="M205.7,9.3C204.8,8.8,203.3,8.8,202.4,9.3C201.5,9.8,201.5,10.7,202.4,11.2C203.3,11.7,204.8,11.7,205.7,11.2C206.6,10.7,206.6,9.8,205.7,9.3Z" fill="url(#Gradient-84)" transform="translate(204.05,10.25) translate(-204.05,-10.25)"/>
                    <path class="st144" d="M205.7,9.3C204.8,8.8,203.3,8.8,202.4,9.3C201.5,9.8,201.5,10.7,202.4,11.2C203.3,11.7,204.8,11.7,205.7,11.2C206.6,10.7,206.6,9.8,205.7,9.3ZM203,9.1L205,9.1C205.1,9.1,205.1,9.2,205.1,9.2C205.1,9.2,205,9.3,205,9.3L203,9.3C202.9,9.3,202.9,9.2,202.9,9.2C202.9,9.2,202.9,9.1,203,9.1ZM202.3,9.5L205.8,9.5C205.9,9.5,206,9.6,206,9.7C206,9.8,205.9,9.9,205.8,9.9L202.3,9.9C202.2,9.9,202.1,9.8,202.1,9.7C202.2,9.6,202.2,9.5,202.3,9.5ZM205.1,11.4L203,11.4C202.9,11.4,202.9,11.3,202.9,11.2C202.9,11.1,203,11,203,11L205.1,11C205.2,11,205.2,11.1,205.2,11.2C205.2,11.3,205.2,11.4,205.1,11.4ZM205.8,10.9L202.3,10.9C202.2,10.9,202.1,10.8,202.1,10.7C202.1,10.6,202.2,10.5,202.3,10.5L205.8,10.5C205.9,10.5,206,10.6,206,10.7C206,10.8,205.9,10.9,205.8,10.9ZM206.1,10.3L202,10.3C201.9,10.3,201.8,10.2,201.8,10.1C201.8,10,201.9,9.9,202,9.9L206.1,9.9C206.2,9.9,206.3,10,206.3,10.1C206.3,10.2,206.2,10.3,206.1,10.3Z" fill="#362A77" transform="translate(204.05,10.25) translate(-204.05,-10.25)"/>
                    <g transform="translate(204.05,11.0875) translate(-204.05,-11.0875)">
                        <path class="st147" d="M206.4,10.3L206.4,10.7C206.4,11,206.2,11.4,205.7,11.6C204.8,12.1,203.3,12.1,202.4,11.6C201.9,11.4,201.7,11,201.7,10.7L201.7,10.2C201.7,10.5,201.9,11,202.4,11.2C203.3,11.7,204.8,11.7,205.7,11.2C206.2,11,206.4,10.6,206.4,10.3Z" fill="url(#Gradient-85)" transform="translate(204.05,11.0875) translate(-204.05,-11.0875)"/>
                    </g>
                </g>
                <g transform="translate(199.564,13.4) translate(-199.564,-13.4)">
                    <path class="st148" d="M201.9,12.7C201.4,12.4,200.8,12.3,200.2,12.3C200.2,12.3,200.1,12.3,200.1,12.3L200.1,12.3L200.1,12.3L199.9,12.3C199.9,12.3,199.9,12.3,199.9,12.3L197.6,12.5L198.1,13.4C198,13.8,198.2,14.2,198.8,14.6L200.4,14.7L201.8,14.2C201.8,14.2,201.9,14.2,201.9,14.1C202,14.1,202,14,202.1,14C202.8,13.5,202.8,13.3,201.9,12.7Z" fill="url(#Gradient-86)" opacity="0.33" transform="translate(200.101,13.5) translate(-200.101,-13.5)"/>
                    <path class="st149" d="M200.5,12.3C199.6,11.8,198.1,11.8,197.2,12.3C196.3,12.8,196.3,13.7,197.2,14.2C198.1,14.7,199.6,14.7,200.5,14.2C201.5,13.7,201.5,12.8,200.5,12.3Z" fill="url(#Gradient-87)" transform="translate(198.887,13.25) translate(-198.887,-13.25)"/>
                    <path class="st144" d="M200.5,12.3C199.6,11.8,198.1,11.8,197.2,12.3C196.3,12.8,196.3,13.7,197.2,14.2C198.1,14.7,199.6,14.7,200.5,14.2C201.5,13.7,201.5,12.8,200.5,12.3ZM197.9,12.1L200,12.1C200.1,12.1,200.1,12.2,200.1,12.2C200.1,12.3,200,12.3,200,12.3L197.9,12.3C197.8,12.3,197.8,12.2,197.8,12.2C197.8,12.2,197.8,12.1,197.9,12.1ZM197.2,12.5L200.7,12.5C200.8,12.5,200.9,12.6,200.9,12.7C200.9,12.8,200.8,12.9,200.7,12.9L197.2,12.9C197.1,12.9,197,12.8,197,12.7C197,12.6,197.1,12.5,197.2,12.5ZM200,14.4L197.9,14.4C197.8,14.4,197.8,14.3,197.8,14.2C197.8,14.1,197.9,14,197.9,14L200,14C200.1,14,200.1,14.1,200.1,14.2C200.1,14.3,200.1,14.4,200,14.4ZM200.7,13.9L197.2,13.9C197.1,13.9,197,13.8,197,13.7C197,13.6,197.1,13.5,197.2,13.5L200.7,13.5C200.8,13.5,200.9,13.6,200.9,13.7C200.9,13.8,200.8,13.9,200.7,13.9ZM201,13.3L196.9,13.3C196.8,13.3,196.7,13.2,196.7,13.1C196.7,13,196.8,12.9,196.9,12.9L201,12.9C201.1,12.9,201.2,13,201.2,13.1C201.1,13.2,201.1,13.3,201,13.3Z" fill="#362A77" transform="translate(198.887,13.25) translate(-198.887,-13.25)"/>
                    <g transform="translate(198.95,13.9875) translate(-198.95,-13.9875)">
                        <path class="st150" d="M201.3,13.2L201.3,13.6C201.3,13.9,201.1,14.3,200.6,14.5C199.7,15,198.2,15,197.3,14.5C196.8,14.3,196.6,13.9,196.6,13.6L196.6,13.1C196.6,13.4,196.8,13.9,197.3,14.1C198.2,14.6,199.7,14.6,200.6,14.1C201,13.9,201.3,13.6,201.3,13.2Z" fill="url(#Gradient-88)" transform="translate(198.95,13.9875) translate(-198.95,-13.9875)"/>
                    </g>
                </g>
                <g transform="translate(198.206,6.35) translate(-198.206,-6.35)">
                    <path class="st151" d="M200.5,5.6C200,5.3,199.4,5.2,198.8,5.2C198.8,5.2,198.7,5.2,198.7,5.2L198.7,5.2L198.7,5.2L198.5,5.2C198.5,5.2,198.5,5.2,198.5,5.2L196,5.5L196.5,6.4C196.4,6.8,196.6,7.2,197.2,7.6L198.8,7.7L200.2,7.2C200.2,7.2,200.3,7.2,200.3,7.1C200.4,7.1,200.4,7,200.5,7C201.5,6.5,201.4,6.2,200.5,5.6Z" fill="url(#Gradient-89)" opacity="0.33" transform="translate(198.606,6.45) translate(-198.606,-6.45)"/>
                    <path class="st152" d="M199.2,5.2C198.3,4.7,196.8,4.7,195.9,5.2C195,5.7,195,6.6,195.9,7.1C196.8,7.6,198.3,7.6,199.2,7.1C200.1,6.6,200.1,5.7,199.2,5.2Z" fill="url(#Gradient-90)" transform="translate(197.55,6.15) translate(-197.55,-6.15)"/>
                    <path class="st144" d="M199.2,5.2C198.3,4.7,196.8,4.7,195.9,5.2C195,5.7,195,6.6,195.9,7.1C196.8,7.6,198.3,7.6,199.2,7.1C200.1,6.6,200.1,5.7,199.2,5.2ZM196.5,5L198.6,5C198.7,5,198.7,5.1,198.7,5.1C198.7,5.2,198.6,5.2,198.6,5.2L196.5,5.2C196.4,5.2,196.4,5.1,196.4,5.1C196.4,5.1,196.5,5,196.5,5ZM195.8,5.4L199.3,5.4C199.4,5.4,199.5,5.5,199.5,5.6C199.5,5.7,199.4,5.8,199.3,5.8L195.8,5.8C195.7,5.8,195.6,5.7,195.6,5.6C195.7,5.5,195.8,5.4,195.8,5.4ZM198.7,7.3L196.6,7.3C196.5,7.3,196.5,7.2,196.5,7.1C196.5,7,196.6,6.9,196.6,6.9L198.7,6.9C198.8,6.9,198.8,7,198.8,7.1C198.8,7.2,198.7,7.3,198.7,7.3ZM199.4,6.8L195.9,6.8C195.8,6.8,195.7,6.7,195.7,6.6C195.7,6.5,195.8,6.4,195.9,6.4L199.4,6.4C199.5,6.4,199.6,6.5,199.6,6.6C199.5,6.7,199.4,6.8,199.4,6.8ZM199.6,6.2L195.5,6.2C195.4,6.2,195.3,6.1,195.3,6C195.3,5.9,195.4,5.8,195.5,5.8L199.6,5.8C199.7,5.8,199.8,5.9,199.8,6C199.8,6.1,199.7,6.2,199.6,6.2Z" fill="#362A77" transform="translate(197.55,6.15) translate(-197.55,-6.15)"/>
                    <g transform="translate(197.55,6.9875) translate(-197.55,-6.9875)">
                        <path class="st153" d="M199.9,6.2L199.9,6.6C199.9,6.9,199.7,7.3,199.2,7.5C198.3,8,196.8,8,195.9,7.5C195.4,7.3,195.2,6.9,195.2,6.6L195.2,6.1C195.2,6.4,195.4,6.9,195.9,7.1C196.8,7.6,198.3,7.6,199.2,7.1C199.7,6.9,199.9,6.5,199.9,6.2Z" fill="url(#Gradient-91)" transform="translate(197.55,6.9875) translate(-197.55,-6.9875)"/>
                    </g>
                </g>
                <g transform="translate(192.839,9.5) translate(-192.839,-9.5)">
                    <path class="st154" d="M195.1,8.8C194.6,8.5,194,8.4,193.4,8.4C193.4,8.4,193.3,8.4,193.3,8.4L193.3,8.4L193.3,8.4L193.1,8.4C193.1,8.4,193.1,8.4,193.1,8.4L190.8,8.6L191.3,9.5C191.2,9.9,191.4,10.3,192,10.7L193.6,10.8L195,10.3C195,10.3,195.1,10.3,195.1,10.2C195.2,10.2,195.2,10.1,195.3,10.1C196.1,9.6,196.1,9.3,195.1,8.8Z" fill="url(#Gradient-92)" opacity="0.33" transform="translate(193.339,9.6) translate(-193.339,-9.6)"/>
                    <path class="st155" d="M193.8,8.4C192.9,7.9,191.4,7.9,190.5,8.4C189.6,8.9,189.6,9.8,190.5,10.3C191.4,10.8,192.9,10.8,193.8,10.3C194.8,9.8,194.8,8.9,193.8,8.4Z" fill="url(#Gradient-93)" transform="translate(192.188,9.35) translate(-192.188,-9.35)"/>
                    <path class="st144" d="M193.8,8.4C192.9,7.9,191.4,7.9,190.5,8.4C189.6,8.9,189.6,9.8,190.5,10.3C191.4,10.8,192.9,10.8,193.8,10.3C194.8,9.8,194.8,8.9,193.8,8.4ZM191.2,8.2L193.3,8.2C193.4,8.2,193.4,8.3,193.4,8.3C193.4,8.4,193.3,8.4,193.3,8.4L191.2,8.4C191.1,8.4,191.1,8.3,191.1,8.3C191,8.2,191.1,8.2,191.2,8.2ZM190.5,8.6L194,8.6C194.1,8.6,194.2,8.7,194.2,8.8C194.2,8.9,194.1,9,194,9L190.5,9C190.4,9,190.3,8.9,190.3,8.8C190.3,8.6,190.4,8.6,190.5,8.6ZM193.3,10.4L191.2,10.4C191.1,10.4,191.1,10.3,191.1,10.2C191.1,10.1,191.2,10,191.2,10L193.3,10C193.4,10,193.4,10.1,193.4,10.2C193.4,10.4,193.3,10.4,193.3,10.4ZM194,9.9L190.5,9.9C190.4,9.9,190.3,9.8,190.3,9.7C190.3,9.6,190.4,9.5,190.5,9.5L194,9.5C194.1,9.5,194.2,9.6,194.2,9.7C194.1,9.9,194.1,9.9,194,9.9ZM194.3,9.4L190.2,9.4C190.1,9.4,190,9.3,190,9.2C190,9.1,190,9,190.1,9L194.2,9C194.3,9,194.4,9.1,194.4,9.2C194.4,9.3,194.4,9.4,194.3,9.4Z" fill="#362A77" transform="translate(192.188,9.35) translate(-192.188,-9.35)"/>
                    <g transform="translate(192.15,10.0875) translate(-192.15,-10.0875)">
                        <path class="st156" d="M194.5,9.3L194.5,9.7C194.5,10,194.3,10.4,193.8,10.6C192.9,11.1,191.4,11.1,190.5,10.6C190,10.4,189.8,10,189.8,9.7L189.8,9.2C189.8,9.5,190,10,190.5,10.2C191.4,10.7,192.9,10.7,193.8,10.2C194.3,10,194.5,9.6,194.5,9.3Z" fill="url(#Gradient-94)" transform="translate(192.15,10.0875) translate(-192.15,-10.0875)"/>
                    </g>
                </g>
                <g transform="translate(198.65,73.9) translate(-198.65,-73.9)">
                    <g transform="translate(208.5,75.25) translate(-208.5,-75.25)">
                        <path class="st157" d="M201.2,80.9L215.8,72.5L215.8,69.6L201.2,78Z" fill="#DFD9D9" transform="translate(208.5,75.25) translate(-208.5,-75.25)"/>
                    </g>
                    <g transform="translate(208.5,75.65) translate(-208.5,-75.65)">
                        <path class="st158" d="M201.2,81.4L215.8,73L215.8,69.9L201.2,78.3Z" fill="url(#Gradient-95)" transform="translate(208.5,75.65) translate(-208.5,-75.65)"/>
                    </g>
                    <g transform="translate(191.35,73.9) translate(-191.35,-73.9)">
                        <g transform="translate(191.35,73.65) translate(-191.35,-73.65)">
                            <path class="st159" d="M181.5,69.3L201.2,80.9L201.2,78L181.5,66.4Z" fill="#EAE4E4" transform="translate(191.35,73.65) translate(-191.35,-73.65)"/>
                        </g>
                        <g transform="translate(191.35,74.05) translate(-191.35,-74.05)">
                            <path class="st160" d="M181.5,69.8L201.2,81.4L201.2,78.3L181.5,66.7Z" fill="url(#Gradient-96)" transform="translate(191.35,74.05) translate(-191.35,-74.05)"/>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
    <g id="building-04" opacity="1" clip-path="url(#ClipPath-4)" transform="translate(65.15,182.7) translate(-21.15,-142.55)">
        <g transform="translate(21.15,116.95) translate(-21.15,-116.95)">
            <g transform="translate(21.15,116.95) translate(-21.15,-116.95)">
                <path class="st169" d="M2.4,120.2L15.5,127.8L39.9,113.7L26.8,106.1Z" fill="url(#Gradient-97)" transform="translate(21.15,116.95) translate(-21.15,-116.95)"/>
            </g>
        </g>
        <g transform="translate(27.7,146.35) translate(-27.7,-146.35)">
            <path class="st170" d="M15.5,179L39.9,164.9L39.9,113.7L15.5,127.8Z" fill="url(#Gradient-98)" transform="translate(27.7,146.35) translate(-27.7,-146.35)"/>
        </g>
        <g transform="translate(8.95,149.6) translate(-8.95,-149.6)">
            <path class="st171" d="M2.4,171.5L15.5,179L15.5,127.8L2.4,120.2Z" fill="url(#Gradient-99)" transform="translate(8.95,149.6) translate(-8.95,-149.6)"/>
        </g>
    </g>
    <g id="building-05" opacity="1" clip-path="url(#ClipPath-5)" transform="translate(157.3,188.15) translate(-113.3,-148)">
        <g transform="translate(113.3,148) translate(-113.3,-148)">
            <g transform="translate(113.3,148) translate(-113.3,-148)">
                <path class="st34" d="M113.3,155.1L88.5,140.8L113.3,126.5L138.1,140.8Z" fill="url(#Gradient-100)" transform="translate(113.3,140.8) translate(-113.3,-140.8)"/>
                <path class="st35" d="M138.1,140.8L113.3,155.1L113.3,169.5L138.1,155.2Z" fill="#380471" transform="translate(125.7,155.15) translate(-125.7,-155.15)"/>
                <path class="st36" d="M113.3,169.5L88.5,155.2L88.5,140.8L113.3,155.1Z" fill="#650DBB" transform="translate(100.9,155.15) translate(-100.9,-155.15)"/>
            </g>
            <path class="st37" d="M113.3,153L91.9,140.7L113.5,128.2L134.8,140.6Z" fill="url(#Gradient-101)" transform="translate(113.35,140.6) translate(-113.35,-140.6)"/>
            <g transform="translate(125.75,155.3) translate(-125.75,-155.3)">
                <path class="st38" d="M137.5,146.3L137.5,144.6L133.6,146.8L133.6,148.5Z" fill="url(#Gradient-102)" opacity="0.7" transform="translate(135.55,146.55) translate(-135.55,-146.55)"/>
                <path class="st39" d="M133.6,148.6L133.6,146.8L129.7,149L129.7,150.8Z" fill="url(#Gradient-103)" opacity="0.7" transform="translate(131.65,148.8) translate(-131.65,-148.8)"/>
                <path class="st40" d="M129.6,150.8L129.6,149.1L125.8,151.2L125.8,153Z" fill="url(#Gradient-104)" opacity="0.7" transform="translate(127.7,151.05) translate(-127.7,-151.05)"/>
                <path class="st41" d="M125.7,153.1L125.7,151.3L121.9,153.5L121.9,155.3Z" fill="url(#Gradient-105)" opacity="0.7" transform="translate(123.8,153.3) translate(-123.8,-153.3)"/>
                <path class="st42" d="M121.8,155.3L121.8,153.6L118,155.7L118,157.5Z" fill="url(#Gradient-106)" opacity="0.7" transform="translate(119.9,155.55) translate(-119.9,-155.55)"/>
                <path class="st43" d="M114,158L114,159.8L117.9,157.6L117.9,155.8Z" fill="url(#Gradient-107)" opacity="0.7" transform="translate(115.95,157.8) translate(-115.95,-157.8)"/>
                <path class="st44" d="M137.5,149.4L137.5,147.7L133.6,149.9L133.6,151.6Z" fill="url(#Gradient-108)" opacity="0.7" transform="translate(135.55,149.65) translate(-135.55,-149.65)"/>
                <path class="st45" d="M129.7,153.9L133.6,151.7L133.6,149.9L129.7,152.1Z" fill="url(#Gradient-109)" opacity="0.7" transform="translate(131.65,151.9) translate(-131.65,-151.9)"/>
                <path class="st46" d="M125.8,156.1L129.6,153.9L129.6,152.1L125.8,154.3Z" fill="url(#Gradient-110)" opacity="0.7" transform="translate(127.7,154.1) translate(-127.7,-154.1)"/>
                <path class="st47" d="M121.9,158.4L125.7,156.2L125.7,154.4L121.9,156.6Z" fill="url(#Gradient-111)" opacity="0.7" transform="translate(123.8,156.4) translate(-123.8,-156.4)"/>
                <path class="st48" d="M118,160.6L121.8,158.4L121.8,156.6L118,158.8Z" fill="url(#Gradient-112)" opacity="0.7" transform="translate(119.9,158.6) translate(-119.9,-158.6)"/>
                <path class="st49" d="M114,161.1L114,162.9L117.9,160.6L117.9,158.9Z" fill="url(#Gradient-113)" opacity="0.7" transform="translate(115.95,160.9) translate(-115.95,-160.9)"/>
                <path class="st50" d="M137.5,152.5L137.5,150.7L133.6,153L133.6,154.7Z" fill="url(#Gradient-114)" opacity="0.7" transform="translate(135.55,152.7) translate(-135.55,-152.7)"/>
                <path class="st51" d="M129.7,156.9L133.6,154.8L133.6,153L129.7,155.2Z" fill="url(#Gradient-115)" opacity="0.7" transform="translate(131.65,154.95) translate(-131.65,-154.95)"/>
                <path class="st52" d="M125.8,159.2L129.6,157L129.6,155.3L125.8,157.4Z" fill="url(#Gradient-116)" opacity="0.7" transform="translate(127.7,157.25) translate(-127.7,-157.25)"/>
                <path class="st53" d="M121.9,161.5L125.7,159.2L125.7,157.5L121.9,159.7Z" fill="url(#Gradient-117)" opacity="0.7" transform="translate(123.8,159.5) translate(-123.8,-159.5)"/>
                <path class="st54" d="M118,163.7L121.8,161.5L121.8,159.8L118,161.9Z" fill="url(#Gradient-118)" opacity="0.7" transform="translate(119.9,161.75) translate(-119.9,-161.75)"/>
                <path class="st55" d="M114,164.2L114,166L117.9,163.8L117.9,162Z" fill="url(#Gradient-119)" opacity="0.7" transform="translate(115.95,164) translate(-115.95,-164)"/>
            </g>
            <g transform="translate(100.75,155) translate(-100.75,-155)">
                <path class="st56" d="M89,144.3L89,146L92.9,148.3L92.9,146.5Z" fill="url(#Gradient-120)" transform="translate(90.95,146.3) translate(-90.95,-146.3)"/>
                <path class="st57" d="M96.8,150.5L96.8,148.7L93,146.6L93,148.3Z" fill="url(#Gradient-121)" transform="translate(94.9,148.55) translate(-94.9,-148.55)"/>
                <path class="st58" d="M100.7,152.7L100.7,151L96.9,148.8L96.9,150.6Z" fill="url(#Gradient-122)" transform="translate(98.8,150.75) translate(-98.8,-150.75)"/>
                <path class="st59" d="M104.6,155L104.6,153.2L100.9,151L100.9,152.8Z" fill="url(#Gradient-123)" transform="translate(102.75,153) translate(-102.75,-153)"/>
                <path class="st60" d="M108.6,157.2L108.6,155.5L104.8,153.3L104.8,155Z" fill="url(#Gradient-124)" transform="translate(106.7,155.25) translate(-106.7,-155.25)"/>
                <path class="st61" d="M108.7,157.3L112.5,159.5L112.5,157.7L108.7,155.5Z" fill="url(#Gradient-125)" transform="translate(110.6,157.5) translate(-110.6,-157.5)"/>
                <path class="st62" d="M89,147.4L89,149.1L92.9,151.3L92.9,149.6Z" fill="url(#Gradient-126)" transform="translate(90.95,149.35) translate(-90.95,-149.35)"/>
                <path class="st63" d="M93,151.4L96.8,153.6L96.8,151.8L93,149.6Z" fill="url(#Gradient-127)" transform="translate(94.9,151.6) translate(-94.9,-151.6)"/>
                <path class="st64" d="M96.9,153.6L100.7,155.8L100.7,154.1L96.9,151.9Z" fill="url(#Gradient-128)" transform="translate(98.8,153.85) translate(-98.8,-153.85)"/>
                <path class="st65" d="M100.9,155.9L104.6,158.1L104.6,156.3L100.9,154.1Z" fill="url(#Gradient-129)" transform="translate(102.75,156.1) translate(-102.75,-156.1)"/>
                <path class="st66" d="M104.8,158.1L108.6,160.3L108.6,158.6L104.8,156.4Z" fill="url(#Gradient-130)" transform="translate(106.7,158.35) translate(-106.7,-158.35)"/>
                <path class="st67" d="M112.5,162.6L112.5,160.8L108.7,158.6L108.7,160.4Z" fill="url(#Gradient-131)" transform="translate(110.6,160.6) translate(-110.6,-160.6)"/>
                <path class="st68" d="M89,150.5L89,152.2L92.9,154.4L92.9,152.7Z" fill="url(#Gradient-132)" transform="translate(90.95,152.45) translate(-90.95,-152.45)"/>
                <path class="st69" d="M93,154.5L96.8,156.7L96.8,154.9L93,152.7Z" fill="url(#Gradient-133)" transform="translate(94.9,154.7) translate(-94.9,-154.7)"/>
                <path class="st70" d="M96.9,156.7L100.7,158.9L100.7,157.2L96.9,155Z" fill="url(#Gradient-134)" transform="translate(98.8,156.95) translate(-98.8,-156.95)"/>
                <path class="st71" d="M100.9,159L104.6,161.2L104.6,159.4L100.9,157.2Z" fill="url(#Gradient-135)" transform="translate(102.75,159.2) translate(-102.75,-159.2)"/>
                <path class="st72" d="M104.8,161.2L108.6,163.4L108.6,161.6L104.8,159.5Z" fill="url(#Gradient-136)" transform="translate(106.7,161.45) translate(-106.7,-161.45)"/>
                <path class="st73" d="M112.5,165.7L112.5,163.9L108.7,161.7L108.7,163.5Z" fill="url(#Gradient-137)" transform="translate(110.6,163.7) translate(-110.6,-163.7)"/>
            </g>
            <g transform="translate(121.6,137.5) translate(-121.6,-137.5)">
                <path class="st74" d="M122.6,137.3L121.5,142.1L127.1,142.1L130.4,140.3L123.3,136.3Z" fill="url(#Gradient-138)" opacity="0.33" transform="translate(125.95,139.2) translate(-125.95,-139.2)"/>
                <g transform="translate(119.65,137.45) translate(-119.65,-137.45)">
                    <path class="st75" d="M121.5,140.9L112.8,135.8L117.8,132.9L126.5,138Z" fill="url(#Gradient-139)" transform="translate(119.65,136.9) translate(-119.65,-136.9)"/>
                    <path class="st76" d="M126.5,139.2L121.5,142L121.5,140.9L126.5,138Z" fill="url(#Gradient-140)" transform="translate(124,140) translate(-124,-140)"/>
                    <path class="st77" d="M121.5,142L112.8,137L112.8,135.8L121.5,140.9Z" fill="url(#Gradient-141)" transform="translate(117.15,138.9) translate(-117.15,-138.9)"/>
                </g>
                <path class="st78" d="M121.5,136.7L120.8,139.6L123.2,139.5L125.7,138.1L122,136Z" fill="url(#Gradient-142)" opacity="0.33" transform="translate(123.25,137.8) translate(-123.25,-137.8)"/>
                <g transform="translate(119.45,136.65) translate(-119.45,-136.65)">
                    <path class="st79" d="M120.8,138.6L115.2,135.3L118,133.7L123.7,136.9Z" fill="url(#Gradient-143)" transform="translate(119.45,136.15) translate(-119.45,-136.15)"/>
                    <path class="st80" d="M123.7,138L120.8,139.6L120.8,138.6L123.7,136.9Z" fill="url(#Gradient-144)" transform="translate(122.25,138.25) translate(-122.25,-138.25)"/>
                    <path class="st81" d="M120.8,139.6L115.2,136.4L115.2,135.3L120.8,138.6Z" fill="url(#Gradient-145)" transform="translate(118,137.45) translate(-118,-137.45)"/>
                </g>
            </g>
            <g transform="translate(103.125,140.212) translate(-103.125,-140.212)">
                <path class="st82" d="M106.4,138.9C105.7,138.5,104.8,138.3,103.9,138.3C103.8,138.3,103.8,138.3,103.7,138.3L103.6,138.3L103.5,138.3L103.3,138.3C103.3,138.3,103.3,138.3,103.3,138.3L100,138.6L100.7,139.8C100.6,140.4,100.9,141,101.7,141.4L104,142.4L106,141.7C106,141.7,106.1,141.6,106.1,141.6C106.2,141.6,106.3,141.5,106.4,141.5C107.8,140.9,107.8,139.6,106.4,138.9Z" fill="url(#Gradient-146)" opacity="0.33" transform="translate(103.725,140.35) translate(-103.725,-140.35)"/>
                <path class="st83" d="M104.6,138.2C103.3,137.4,101.2,137.4,99.9,138.2C98.6,139,98.7,140.2,100,141C101.3,141.7,103.5,141.8,104.8,141C105.9,140.2,105.9,139,104.6,138.2Z" fill="url(#Gradient-147)" transform="translate(102.288,139.582) translate(-102.288,-139.582)"/>
                <path class="st84" d="M104.6,138.2C103.3,137.4,101.2,137.4,99.9,138.2C98.6,139,98.7,140.2,100,141C101.3,141.7,103.5,141.8,104.8,141C105.9,140.2,105.9,139,104.6,138.2ZM100.8,138L103.7,138C103.8,138,103.9,138.1,103.9,138.2C103.9,138.3,103.8,138.4,103.7,138.4L100.8,138.4C100.7,138.4,100.6,138.3,100.6,138.2C100.6,138.1,100.7,138,100.8,138ZM99.8,138.6L104.8,138.6C104.9,138.6,105,138.7,105,138.8C105,138.9,104.9,139,104.8,139L99.8,139C99.7,139,99.6,138.9,99.6,138.8C99.6,138.6,99.7,138.6,99.8,138.6ZM103.8,141.2L100.9,141.2C100.8,141.2,100.7,141.1,100.7,141C100.7,140.9,100.8,140.8,100.9,140.8L103.8,140.8C103.9,140.8,104,140.9,104,141C104,141.1,103.9,141.2,103.8,141.2ZM104.8,140.5L99.8,140.5C99.7,140.5,99.6,140.4,99.6,140.2C99.6,140,99.7,139.9,99.8,139.9L104.8,139.9C104.9,139.9,105,140,105,140.2C105,140.4,104.9,140.5,104.8,140.5ZM105.2,139.6L99.3,139.6C99.2,139.6,99.1,139.5,99.1,139.4C99.1,139.3,99.2,139.2,99.3,139.2L105.2,139.2C105.4,139.2,105.4,139.3,105.4,139.4C105.4,139.5,105.3,139.6,105.2,139.6Z" fill="url(#Gradient-148)" transform="translate(102.288,139.582) translate(-102.288,-139.582)"/>
                <g transform="translate(102.2,141.162) translate(-102.2,-141.162)">
                    <path class="st85" d="M105.6,139.6L105.6,141C105.6,141.5,105.3,142,104.6,142.3C103.3,143,101.1,143,99.8,142.3C99.1,142,98.8,141.5,98.8,141L98.8,139.5C98.8,139.9,99.1,140.6,99.8,140.9C101.1,141.6,103.3,141.6,104.6,140.9C105.3,140.6,105.6,140,105.6,139.6Z" fill="url(#Gradient-149)" transform="translate(102.2,141.162) translate(-102.2,-141.162)"/>
                </g>
            </g>
            <g transform="translate(114.574,146.531) translate(-114.574,-146.531)">
                <path class="st86" d="M117.9,145.2C117.2,144.8,116.3,144.6,115.4,144.6C115.3,144.6,115.3,144.6,115.2,144.6L115.1,144.6L115.1,144.6L114.9,144.6C114.9,144.6,114.9,144.6,114.8,144.6L111.5,144.9L112.2,146.1C112.1,146.7,112.4,147.3,113.2,147.8L115.5,148.8L117.5,148.1C117.5,148.1,117.6,148,117.6,148C117.7,148,117.8,147.9,117.9,147.9C119.2,147.2,119.2,145.9,117.9,145.2Z" fill="url(#Gradient-150)" opacity="0.33" transform="translate(115.188,146.7) translate(-115.188,-146.7)"/>
                <path class="st87" d="M116,144.5C114.6,143.8,112.5,143.7,111.2,144.5C109.9,145.2,110,146.5,111.3,147.2C112.6,148,114.8,148,116.1,147.2C117.4,146.5,117.4,145.3,116,144.5Z" fill="url(#Gradient-151)" transform="translate(113.668,145.868) translate(-113.668,-145.868)"/>
                <path class="st88" d="M116,144.5C114.6,143.8,112.5,143.7,111.2,144.5C109.9,145.2,110,146.5,111.3,147.2C112.6,148,114.8,148,116.1,147.2C117.4,146.5,117.4,145.3,116,144.5ZM112.2,144.3L115.1,144.3C115.2,144.3,115.3,144.4,115.3,144.5C115.3,144.6,115.2,144.7,115.1,144.7L112.2,144.7C112.1,144.7,112,144.6,112,144.5C112,144.4,112.1,144.3,112.2,144.3ZM111.2,144.9L116.2,144.9C116.3,144.9,116.4,145,116.4,145.1C116.4,145.2,116.3,145.3,116.2,145.3L111.2,145.3C111.1,145.3,111,145.2,111,145.1C111,145,111.1,144.9,111.2,144.9ZM115.3,147.5L112.4,147.5C112.3,147.5,112.2,147.4,112.2,147.3C112.2,147.2,112.3,147.1,112.4,147.1L115.3,147.1C115.4,147.1,115.5,147.2,115.5,147.3C115.4,147.4,115.3,147.5,115.3,147.5ZM116.2,146.8L111.2,146.8C111.1,146.8,111,146.7,111,146.5C111,146.3,111.1,146.2,111.2,146.2L116.2,146.2C116.3,146.2,116.4,146.3,116.4,146.5C116.5,146.7,116.4,146.8,116.2,146.8ZM116.7,145.9L110.8,145.9C110.7,145.9,110.6,145.8,110.6,145.7C110.6,145.6,110.7,145.5,110.8,145.5L116.7,145.5C116.8,145.5,116.9,145.6,116.9,145.7C116.9,145.8,116.8,145.9,116.7,145.9Z" fill="url(#Gradient-152)" transform="translate(113.668,145.868) translate(-113.668,-145.868)"/>
                <g transform="translate(113.7,147.463) translate(-113.7,-147.463)">
                    <path class="st89" d="M117.1,145.9L117.1,147.3C117.1,147.8,116.8,148.3,116.1,148.6C114.8,149.3,112.6,149.3,111.3,148.6C110.7,148.3,110.3,147.8,110.3,147.3L110.3,145.8C110.3,146.3,110.6,146.9,111.3,147.2C112.6,147.9,114.7,148,116.1,147.2C116.7,146.9,117.1,146.4,117.1,145.9Z" fill="url(#Gradient-153)" transform="translate(113.7,147.463) translate(-113.7,-147.463)"/>
                </g>
            </g>
        </g>
    </g>
    <g id="building-06" opacity="1" clip-path="url(#ClipPath-6)" transform="translate(244.65,178.129) translate(-200.65,-137.979)">
        <path class="st165" d="M221.9,110.2L222,168.7L198.9,157.4L198.9,97.5Z" fill="#CCCBCB" transform="translate(210.45,133.1) translate(-210.45,-133.1)"/>
        <path class="st166" d="M202.2,118.9L202.3,179.7L179.2,166.4L179.1,106.2Z" fill="url(#Gradient-154)" transform="translate(190.7,142.95) translate(-190.7,-142.95)"/>
        <path class="st167" d="M202.3,179.7L222,168.6C222,168.6,221.8,112,221.9,110.4C221.7,109.1,213,107.8,213,107.8L202.1,117.9L202.3,179.7Z" fill="url(#Gradient-155)" transform="translate(212.05,143.75) translate(-212.05,-143.75)"/>
        <path class="st168" d="M211.2,104.1L199.2,97.4C197.1,96.5,194.6,95.9,191.9,96.5C191.8,96.5,191.6,96.6,191.5,96.6C185.6,98.3,180.5,104.6,179.2,106.2L190.6,112.6L202,119C203.3,117.3,208.4,111,214.3,109.3C214.5,109.3,214.6,109.2,214.8,109.2C217.6,108.5,220.2,109.1,222.2,110.1L211.2,104.1Z" fill="url(#Gradient-156)" transform="translate(200.7,107.629) translate(-200.7,-107.629)"/>
    </g></svg>
  </div>
</template>

<script>
export default {
  name: 'Buildings',
  props: {},
  components: {},
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {},
  mounted() {
    /* eslint-disable */
window.KeyshapeJS=function(){function t(a){return"undefined"!==typeof a}function x(a,b){return a&&0==a.indexOf(b)}function H(a){if(!isFinite(a))throw Error("Non-finite value");}function R(a){if(14>=a)return 16;var b=S[a];b||(b=t(ca[a])?0|(a.toLowerCase().indexOf("color")==a.length-5?48:0):1);return b}function K(a){return 0<=a?Math.pow(a,1/3):-Math.pow(-a,1/3)}function da(a,b,c,d){if(0==a)return 0==b?b=-d/c:(a=Math.sqrt(c*c-4*b*d),d=(-c+a)/(2*b),0<=d&&1>=d?b=d:(d=(-c-a)/(2*b),b=0<=d&&1>=d?d:0)),b;
var e=c/a-b*b/(a*a)/3;c=b*b*b/(a*a*a)/13.5-b*c/(a*a)/3+d/a;var n=c*c/4+e*e*e/27;b=-b/(3*a);if(0>=n){if(0==e&&0==c)return-K(d/a);a=Math.sqrt(c*c/4-n);d=Math.acos(-c/2/a);c=Math.cos(d/3);d=Math.sqrt(3)*Math.sin(d/3);a=K(a);e=2*a*c+b;if(0<=e&&1>=e)return e;e=-a*(c+d)+b;if(0<=e&&1>=e)return e;e=a*(d-c)+b;if(0<=e&&1>=e)return e}else{a=K(-c/2+Math.sqrt(n));c=K(-c/2-Math.sqrt(n));d=a+c+b;if(0<=d&&1>=d)return d;d=-(a+c)/2+b;if(0<=d&&1>=d)return d}return 0}function ea(a,b){if(48==a&&"number"===typeof b)return"rgba("+
(b>>>24)+","+(b>>>16&255)+","+(b>>>8&255)+","+(b&255)/255+")";if(64==a)return b=b.map(function(a){return a+"px"}),b.join(",");if(96==a){a="";for(var c=b.length,d=0;d<c;d+=2)a+=b[d],a+=b[d+1].join(",");return a}if(80==a){if(0==b[0])return"none";a="";c=b.length;for(d=0;d<c;)a+=T[b[d]],1==b[d]?a+="("+b[d+1]+") ":5==b[d]?(a+="("+b[d+1]+"px "+b[d+2]+"px "+b[d+3]+"px rgba("+(b[d+4]>>>24)+","+(b[d+4]>>16&255)+","+(b[d+4]>>8&255)+","+(b[d+4]&255)/255+")) ",d+=3):a=2==b[d]?a+("("+b[d+1]+"px) "):7==b[d]?a+
("("+b[d+1]+"deg) "):a+("("+(0>b[d+1]?0:b[d+1])+") "),d+=2;return a}return 32==a?b+"px":b}function y(a){return 0>=a?0:255<=a?255:a}function fa(a,b,c,d){if(16==a||32==a)return(c-b)*d+b;if(0==a)return.5>d?b:c;if(48==a){if("number"===typeof b&&"number"===typeof c){var e=1-d;return(y(e*(b>>>24)+d*(c>>>24))<<24|y(e*(b>>>16&255)+d*(c>>>16&255))<<16|y(e*(b>>>8&255)+d*(c>>>8&255))<<8|y(e*(b&255)+d*(c&255)))>>>0}return.5>d?b:c}if(64==a){0==b.length&&(b=[0]);0==c.length&&(c=[0]);var n=b.length;b.length!=c.length&&
(n=b.length*c.length);var l=[];for(a=0;a<n;++a){var f=b[a%b.length];var h=(c[a%c.length]-f)*d+f;0>h&&(h=0);l.push(h)}return l}if(96==a){if(b.length!=c.length)return.5>d?b:c;n=b.length;l=[];for(a=0;a<n;a+=2){if(b[a]!==c[a])return.5>d?b:c;l[a]=b[a];l[a+1]=[];for(f=0;f<b[a+1].length;++f)l[a+1].push((c[a+1][f]-b[a+1][f])*d+b[a+1][f])}return l}if(80==a){n=b.length;if(n!=c.length)return.5>d?b:c;l=[];for(a=0;a<n;){if(b[a]!=c[a]||1==b[a])return.5>d?b:c;l[a]=b[a];l[a+1]=(c[a+1]-b[a+1])*d+b[a+1];if(5==b[a]){l[a+
2]=(c[a+2]-b[a+2])*d+b[a+2];l[a+3]=(c[a+3]-b[a+3])*d+b[a+3];e=1-d;var g=b[a+4],q=c[a+4];h=e*(g>>>24)+d*(q>>>24);var m=e*(g>>16&255)+d*(q>>16&255);f=e*(g>>8&255)+d*(q>>8&255);e=e*(g&255)+d*(q&255);l[a+4]=(y(m)<<16|y(f)<<8|y(e))+16777216*(y(h)|0);a+=3}a+=2}return l}return 0}function U(a,b){a:{var c=a+b[2];var d=b[4].length;for(var e=0;e<d;++e)if(c<b[4][e]){c=e;break a}c=d-1}d=b[2];e=b[4][c-1]-d;a=(a-e)/(b[4][c]-d-e);if(b[6]&&b[6].length>c-1)if(d=b[6][c-1],1==d[0])if(0>=a)a=0;else if(1<=a)a=1;else{e=
d[1];var n=d[3];a=da(3*e-3*n+1,-6*e+3*n,3*e,-a);a=3*a*(1-a)*(1-a)*d[2]+3*a*a*(1-a)*d[4]+a*a*a}else 2==d[0]?(d=d[1],a=Math.ceil(a*d)/d):3==d[0]&&(d=d[1],a=Math.floor(a*d)/d);return fa(b[1]&240,b[5][c-1],b[5][c],a)}function L(){u||(v=(new Date).getTime()+V)}function O(a){if(a||!E){for(var b=!1,c=0;c<w.length;++c)w[c].J(a)&&(b=!0);if(a)for(;0<I.length;)if(a=I.shift(),c=a[0],1==a[1])c.onfinish&&(c.onfinish(),b=!0),c.I();else if(2==a[1]&&c.onloop)c.onloop();return b}}function W(){L();O(!0)&&!u?(E=!0,M(W)):
E=!1}function N(){E||(E=!0,M(W))}function X(a,b){var c=[];a.split(b).forEach(function(a){c.push(parseFloat(a))});return c}function A(a){-1==a.indexOf(",")&&(a=a.replace(" ",","));return X(a,",")}function Y(a){a._ks||(a._ks={});if(!a._ks.transform){for(var b=a._ks.transform=[],c=0;14>=c;++c)b[c]=0;b[10]=1;b[11]=1;if(a=a.getAttribute("transform")){a=a.trim().split(") ");for(c=a.length-2;0<=c;--c)if(x(a[c],"translate(")){for(var d=0;d<c;d++)a.shift();break}c=a.shift();x(c,"translate(")&&(c=A(c.substring(10)),
b[1]=c[0],b[2]=t(c[1])?c[1]:0,c=a.shift());x(c,"rotate(")&&(c=A(c.substring(7)),b[6]=c[0],c=a.shift());x(c,"skewX(")&&(c=A(c.substring(6)),b[7]=c[0],c=a.shift());x(c,"skewY(")&&(c=A(c.substring(6)),b[8]=c[0],c=a.shift());x(c,"scale(")&&(c=A(c.substring(6)),b[10]=c[0],b[11]=t(c[1])?c[1]:c[0],c=a.shift());x(c,"translate(")&&(c=A(c.substring(10)),b[13]=c[0],b[14]=t(c[1])?c[1]:0)}}}function Z(a){this.l=a;this.A=[];this.C=[];this.v=0;this.s=this.a=this.c=null;this.h=this.f=this.g=0;this.b=1;this.i=this.F=
this.o=!1}function J(a,b,c){b=a[b];void 0===b&&(b=a[c]);return b}function ha(a){return Array.isArray(a)?a:x(a,"cubic-bezier(")?(a=a.substring(13,a.length-1).split(","),[1,parseFloat(a[0]),parseFloat(a[1]),parseFloat(a[2]),parseFloat(a[3])]):x(a,"steps(")?(a=a.substring(6,a.length-1).split(","),[a[1]&&"start"==a[1].trim()?2:3,parseFloat(a[0])]):[0]}function ia(a){a=a.trim();return x(a,"#")?(parseInt(a.substring(1),16)<<8)+255:x(a,"rgba(")?(a=a.substring(5,a.length-1),a=a.split(","),(parseInt(a[0],
10)<<24)+(parseInt(a[1],10)<<16)+(parseInt(a[2],10)<<8)+255*parseFloat(a[3])<<0):a}function aa(a){!1===a.i&&(w.push(a),a.i=!0,!1!==a.l.autoplay&&a.play());return this}function P(a){if(!0===a.i){a._cancel();var b=w.indexOf(a);-1<b&&w.splice(b,1);b=I.indexOf(a);-1<b&&I.splice(b,1);a.i=!1}return this}var Q=Error("Not in timeline list"),ba="mpath posX posY    rotate skewX skewY  scaleX scaleY  anchorX anchorY".split(" "),ja=" translate translate    rotate skewX skewY  scale scale  translate translate".split(" "),
T="none url blur brightness contrast drop-shadow grayscale hue-rotate invert opacity saturate sepia".split(" "),M=window.requestAnimationFrame||window.webkitRequestAnimationFrame||window.mozRequestAnimationFrame||window.oRequestAnimationFrame||window.msRequestAnimationFrame||null;M||(M=function(a){window.setTimeout(a,16)});var S={d:97,fill:48,fillOpacity:16,filter:80,height:33,opacity:16,offsetDistance:33,stroke:48,strokeDasharray:64,strokeDashoffset:32,strokeOpacity:16,strokeWidth:32,transform:1,
width:33},ca=window.getComputedStyle(document.documentElement),E=!1,v=(new Date).getTime(),u,V=0,w=[],I=[];Z.prototype={B:function(a){var b=0;if(null!==this.c){var c=this.j();0<this.b&&null!==c&&c>=this.f?this.h?(this.c=v-this.g/this.b,this.h--,b=2):(b=1,a?this.a=c:this.a=this.s?Math.max(this.s,this.f):this.f):0>this.b&&null!==c&&c<=this.g?this.h&&Infinity!=this.f?(this.c=v-this.f/this.b,this.h--,b=2):(this.h=0,b=1,a?this.a=c:this.a=this.s?Math.min(this.s,this.g):this.g):null!==c&&0!=this.b&&(a&&
null!==this.a&&(this.c=v-this.a/this.b),this.a=null)}this.s=this.j();return b},J:function(a){a&&(this.o&&(this.o=!1,null===this.c&&(0!=this.b&&null!==this.a?(this.c=v-this.a/this.b,this.a=null):this.c=v)),null===this.a&&null!==this.c&&(a=this.B(!1),0!=a&&I.push([this,a])));a=this.j();if(null===a)return!1;for(var b=this.A,c=this.C,d=0;d<b.length;++d){for(var e=b[d],n=!1,l=0;l<c[d].length;++l){var f=c[d][l],h=f[0];if(null!==h){var g=f[2];var q=f[4].length,m=f[4][q-1]-g;g=0==m?f[5][q-1]:a<=g?f[5][0]:
a>=g+f[3]?0==f[3]%m?f[5][q-1]:U(f[3]%m,f):U((a-g)%m,f);0==h?(e._ks.mpath=f[8],e._ks.transform[h]=g,n=!0):14>=h?(e._ks.transform[h]=g,n=!0):(g=ea(f[1]&240,g),f[1]&1?e.setAttribute(h,g):e.style[h]=g)}}if(n){Y(e);n=e._ks.transform;l="";if(f=e._ks.mpath)g=n[0],0>g&&(g=0),100<g&&(g=100),g=g*f[2]/100,h=f[1].getPointAtLength(g),l="translate("+h.x+","+h.y+") ",f[0]&&(.5>g?(g=h,h=f[1].getPointAtLength(.5)):g=f[1].getPointAtLength(g-.5),l+="rotate("+180*Math.atan2(h.y-g.y,h.x-g.x)/Math.PI+") ");for(f=1;f<n.length;++f)h=
n[f],h!=(10==f||11==f?1:0)&&(l+=" "+ja[f]+"(",l=2>=f?l+(1==f?h+",0":"0,"+h):13<=f?l+(13==f?h+",0":"0,"+h):10<=f?l+(10==f?h+",1":"1,"+h):l+h,l+=")");e.setAttribute("transform",l)}}return"running"==this.m()},I:function(){!1!==this.l.autoremove&&"finished"==this.m()&&P(this)},D:function(){if(!this.F){this.F=!0;for(var a=this.A,b=this.C,c=0;c<a.length;++c)for(var d=a[c],e=0;e<b[c].length;++e)14>=b[c][e][0]&&Y(d)}},u:function(a){if("number"==typeof a)return a;if(!t(this.l.markers)||!t(this.l.markers[a]))throw Error("Invalid marker: "+
a);return+this.l.markers[a]},play:function(a){t(a)&&null!==a&&(a=this.u(a),H(a),0>this.b&&a<this.g&&(a=this.g),0<this.b&&a>this.f&&(a=this.f),this.w(a,!0));if(!this.i)throw Q;a=this.j();if(0<this.b&&(null===a||a>=this.f))this.a=this.g;else if(0>this.b&&(null===a||a<=this.g)){if(Infinity==this.f)throw Error("Cannot seek to Infinity");this.a=this.f}else 0==this.b&&null===a&&(this.a=this.g);if(null===this.a)return this;this.c=null;this.o=!0;this.D();N();return this},pause:function(a){if(!this.i)throw Q;
t(a)&&(a=this.u(a),H(a));if("paused"!=this.m()){L();var b=this.j();if(null===b)if(0<=this.b)this.a=this.g;else{if(Infinity==this.f)throw Error("Cannot seek to Infinity");this.a=this.f}null!==this.c&&null===this.a&&(this.a=b);this.c=null;this.o=!1;this.B(!1);this.D();N()}t(a)&&this.w(a,!0);return this},range:function(a,b){if(0==arguments.length)return{"in":this.g,out:this.f};var c=this.u(a),d=this.v;t(b)&&(d=this.u(b));H(c);if(0>c||0>d||c>=d||isNaN(d))throw Error("Invalid range");var e=this.m();this.g=
c;this.f=d;"finished"==e&&"running"==this.m()&&this.play();return this},loop:function(a){if(!t(a))return{count:this.h};this.h=!0===a?Infinity:Math.floor(a);if(0>this.h||isNaN(this.h))this.h=0;return this},j:function(){return null!==this.a?this.a:null===this.c?null:(v-this.c)*this.b},w:function(a,b){b&&L();null!==a&&(this.D(),null!==this.a||null===this.c||0==this.b?(this.a=a,O(!1)):this.c=v-a/this.b,this.i||(this.c=null),this.s=null,this.B(!0),N())},G:function(){return this.j()},time:function(a){if(t(a)){if(!this.i)throw Q;
a=this.u(a);H(a);this.w(a,!0);return this}return this.G()},m:function(){var a=this.j();return this.o?"running":null===a?"idle":null===this.c?"paused":0<this.b&&a>=this.f||0>this.b&&a<=this.g?"finished":"running"},state:function(){return this.m()},duration:function(){return this.v},H:function(a){H(a);L();var b=this.j();this.b=a;null!==b&&this.w(b,!1)},rate:function(a){return t(a)?(this.H(a),this):this.b},marker:function(a){return t(this.l.markers)?this.l.markers[a]:void 0},_cancel:function(){if(!this.i||
"idle"==this.m())return this;this.c=this.a=null;this.o=!1;return this}};return{version:"1.1.0",animate:function(){var a={};if(1==arguments.length%2){a=arguments[arguments.length-1];var b={};for(c in a)b[c]=a[c];a=b}var c=new Z(a);a=arguments;for(var d=b=0;d<a.length-1;d+=2){var e=a[d];var n=e instanceof Element?e:document.getElementById(e.substring(1));if(!n)throw Error("Invalid target: "+e);e=n;n=a[d+1];e._ks||(e._ks={});for(var l=[],f=0;f<n.length;++f){var h=n[f],g=J(h,"p","property");if("string"!=
typeof g||-1!=g.indexOf("-")||""===g||!(0<S[g]||0<=ba.indexOf(g)))throw Error("Invalid property: "+g);var q=ba.indexOf(g);""!==g&&0<=q&&(g=q);q=R(g);var m=J(h,"t","times");if(!m||2>m.length)throw Error("Not enough times");m=m.slice();if(!isFinite(m[0])||0>m[0])throw Error("Invalid time: "+m[0]);for(var B=1;B<m.length;++B)if(!isFinite(m[B])||0>m[B]||m[B]<m[B-1])throw Error("Invalid time: "+m[B]);B=m[0];var v=m[m.length-1]-B,y=h.iterations||0;1>y&&(y=1);v*=y;b<v+B&&(b=v+B);var u=J(h,"v","values");if(!u||
u.length!=m.length)throw Error("Values do not match times");u=u.slice();for(var C=g,k=u,w=R(C)&240,p=0;p<k.length;++p)if(96==w){for(var G=k[p].substring(6,k[p].length-2).match(/[A-DF-Za-df-z][-+0-9eE., ]*/ig),A=[],r=0;r<G.length;++r){A.push(G[r][0]);for(var z=1<G[r].trim().length?G[r].substring(1).split(","):[],F=0;F<z.length;++F)z[F]=parseFloat(z[F]);A.push(z)}k[p]=A}else if(48==w)x(k[p],"#")?(G=9==k[p].length,k[p]=parseInt(k[p].substring(1),16),G||(k[p]=256*k[p]|255)):x(k[p],"url(")||"none"==k[p]||
(console.warn("unsupported color: "+k[p]),k[p]=0);else if(80==w){G=k;A=p;r=k[p];if("none"==r)r=[0];else{z=[];for(var D=r.indexOf("(");0<D;)if(F=T.indexOf(r.substring(0,D)),0<=F){z.push(F);var E=r.indexOf(") ");0>E&&(E=r.length-1);D=r.substring(D+1,E).split(" ");5==F?(z.push(parseFloat(D[0])),z.push(parseFloat(D[1])),z.push(parseFloat(D[2])),z.push(ia(D[3]))):1==F?z.push(D[0]):z.push(parseFloat(D[0]));r=r.substring(E+1).trim();D=r.indexOf("(")}else break;r=z}G[A]=r}else 64==w?"none"!=k[p]?/^[0-9 .]*$/.test(k[p])?
k[p]=X(k[p]," "):(console.warn("unsupported value: "+k[p]),k[p]=[0]):k[p]=[0]:32==w?(H(k[p]),k[p]=parseFloat(k[p])):0===C&&(k[p]=parseFloat(k[p]));C=J(h,"e","easing");k=m.length;for(C||(C=[]);C.length<k;)C.push([1,0,0,.58,1]);for(k=0;k<C.length;++k)C[k]=ha(C[k]);q=[g,q,B,v,m,u,C,y];m=J(h,"mp","motionPath");t(m)&&0===g&&(q[8]=[],q[8][0]=h.motionRotate,h=document.createElementNS("http://www.w3.org/2000/svg","path"),m||(m="M0,0"),h.setAttribute("d",m),q[8][1]=h,q[8][2]=h.getTotalLength());l.push(q)}0<
l.length&&(c.A.push(e),c.C.push(l))}c.v=b;c.g=0;c.f=c.v;aa(c);return c},add:aa,remove:P,removeAll:function(){for(var a=w.length-1;0<=a;--a)P(w[a]);return this},timelines:function(){return w.slice()},globalPlay:function(){u&&(V=u-(new Date).getTime(),u=void 0,N());return this},globalPause:function(){u||(u=v,O(!1));return this},globalState:function(){return u?"paused":"running"}}}();
if(KeyshapeJS.version.indexOf('1.')!=0)throw Error('Expected KeyshapeJS v1.*.*');window.ks=document.ks=KeyshapeJS;(function(ks){
ks.animate("#building-01",[{p:'opacity',t:[2500,3000],v:[1,0],e:[[1,0.42,0,0.58,1],[0]]}],
"#mask-01",[{p:'anchorX',t:[0,1000],v:[-24.4,-24.4],e:[[1,0.42,0,0.58,1],[0]]},{p:'anchorY',t:[0,1000],v:[38.975,-43.425],e:[[1,0.42,0,0.58,1],[0]]},{p:'height',t:[0,1000],v:[0,82.4],e:[[1,0.42,0,0.58,1],[0]]}],
"#building-02",[{p:'opacity',t:[2400,2900],v:[1,0],e:[[1,0.42,0,0.58,1],[0]]}],
"#mask-2",[{p:'anchorX',t:[267,1267],v:[-24.4,-24.4],e:[[1,0.42,0,0.58,1],[0]]},{p:'anchorY',t:[267,1267],v:[38.975,-43.425],e:[[1,0.42,0,0.58,1],[0]]},{p:'height',t:[267,1267],v:[0,82.4],e:[[1,0.42,0,0.58,1],[0]]}],
"#building-03",[{p:'opacity',t:[2300,2800],v:[1,0],e:[[1,0.42,0,0.58,1],[0]]}],
"#mask-3",[{p:'anchorX',t:[800,1800],v:[-24.4,-24.4],e:[[1,0.42,0,0.58,1],[0]]},{p:'anchorY',t:[800,1800],v:[38.975,-43.425],e:[[1,0.42,0,0.58,1],[0]]},{p:'height',t:[800,1800],v:[0,82.4],e:[[1,0.42,0,0.58,1],[0]]}],
"#building-04",[{p:'opacity',t:[2100,2600],v:[1,0],e:[[1,0.42,0,0.58,1],[0]]}],
"#mask-4",[{p:'anchorX',t:[433,1433],v:[-24.4,-24.4],e:[[1,0.42,0,0.58,1],[0]]},{p:'anchorY',t:[433,1433],v:[38.975,-43.425],e:[[1,0.42,0,0.58,1],[0]]},{p:'height',t:[433,1433],v:[0,82.4],e:[[1,0.42,0,0.58,1],[0]]}],
"#building-05",[{p:'opacity',t:[2167,2667],v:[1,0],e:[[1,0.42,0,0.58,1],[0]]}],
"#mask-5",[{p:'anchorX',t:[667,1667],v:[-24.4,-24.4],e:[[1,0.42,0,0.58,1],[0]]},{p:'anchorY',t:[667,1667],v:[38.975,-21.625],e:[[1,0.42,0,0.58,1],[0]]},{p:'height',t:[667,1667],v:[0,47.15],e:[[1,0.42,0,0.58,1],[0]]}],
"#building-06",[{p:'opacity',t:[2000,2500],v:[1,0],e:[[1,0.42,0,0.58,1],[0]]}],
"#mask-6",[{p:'mpath',t:[1000,2000],v:['0%','100%'],e:[[0],[0]],mp:"M197.3,138L197.4,138.025"},{p:'anchorX',t:[1000,2000],v:[-24.4,-24.4],e:[[1,0.42,0,0.58,1],[0]]},{p:'anchorY',t:[1000,2000],v:[38.975,-43.425],e:[[1,0.42,0,0.58,1],[0]]},{p:'height',t:[1000,2000],v:[0,82.4],e:[[1,0.42,0,0.58,1],[0]]}],
{autoremove:false}).range(0,3300).loop(true);
if(document.location.search.substr(1).split('&').indexOf('global=paused')>=0)ks.globalPause()})(KeyshapeJS);
  }
};
</script>

<style lang='scss' scoped>
#buildings {}
</style>
